@import "../../../css/mixins";
.container {
  width: 100%;
  min-height: 180px;
  padding: 0 5%;
  background-image: url("/images/bg-banner-2.jpg");
  background-size: cover;

  .content {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 20%;
    flex-wrap: wrap;

    h1 {
      margin: 0;
      font-size: 40px;
      font-weight: bold;
      color: var(--red);

      span {
        color: var(--gray);
      }
    }
    img {
      display: block;
    }

    button {
      min-width: auto;
      color: white;
    }
  }
  @media screen and (max-width: 1099px) {
    gap: 100px;
  }
  @media screen and (max-width: 999px) {
    padding-bottom: 15px;
  }
}
