@import "../../../css/_mixins.scss";

.container {
  position: relative;
  min-width: 300px; 
  height: 60px;
  padding: 0 50px;
  border-radius: 30px;
  letter-spacing: 0;
  white-space: nowrap;
  font-family: var(--font-family-montserrat);
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  background-color: var(--yellow);
  border: 3px solid var(--yellow);
  color: var(--white);

  @media screen and (max-width: 800px) {
    font-size: 18px;
    max-width:100%;
    padding: 0 min(50px, 4%);
  }

  &.red {
    background-color: var(--red);
    border: 3px solid var(--red);
    color: var(--red);
  }

  &.yellow {
    background-color: var(--yellow);
    border: 3px solid var(--yellow);
    color: var(--yellow);
  }

  &.gray {
    background-color: var(--gray);
    border: 3px solid var(--gray);
    color: var(--gray);
  }

  &.small {
    font-size: 20px;
    height: 50px;
    min-width: 200px;
  }

  &.very-small {
    font-size: 15px;
    height: 30px;
    min-width: 100px;
  }

  &.outline {
    background-color: var(--white);
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.loading {
    color: transparent;
    pointer-events: none;

    .spiner {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
    }
  }
}