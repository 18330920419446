.container {
  display        : flex;
  justify-content: center;
  align-items    : center;
  flex-direction : column;
  width          : 100%;
  min-height     : 75vh;
  text-align     : center;

  .title {
    text-align : center;
    white-space: nowrap;
    color      : var(--red);
    font-size  : 45px;
    font-style : normal;
    font-weight: 700;
  }

  .actions {
    display   : flex;
    margin-top: 44px;

    .btnInstructions {
      position: relative;
      width: 320px;
      border: 3px solid var(--red);
      color: var(--red);
      height: 60px;
      padding: 0 50px;
      border-radius: 30px;
      letter-spacing: 0;
      white-space: nowrap;
      font-family: var(--font-family-montserrat);
      font-size: 26px;
      font-style: normal;
      font-weight: 700;
      text-decoration: none;
      line-height: 54px;
    }
  }

  .uploadWrapper {
    display    : flex;
    align-items: center;
    margin-top : 70px;

    .preview {
      width: 320px;

      img {
        max-width: 100%;
      }

      i {
        font-size: 200px;
        color    : var(--gray);
      }
    }

    .uploadWidget {
      flex       : 1;
      width      : 100%;
      margin-left: 16px;
    }
  }

  .btnAction {
    position: relative;
    width   : 320px;

    &:not(:first-child) {
      margin-left: 16px;
    }

    i {
      position : absolute;
      top      : 50%;
      right    : 30px;
      transform: translateY(-50%);
    }
  }

  .downloadWrapper {
    margin-left: 16px;
  }

  .uploadDetail {
    display    : flex;
    align-items: center;
    margin-top : 36px;

    .fileLabel {
      font-size     : 22px;
      line-height   : 32px;
      font-weight   : bold;
      color         : var(--gray);
      letter-spacing: -0.52px;
    }

    .fileDetail {
      display       : flex;
      align-items   : center;
      margin-left   : 18px;
      font-size     : 15px;
      color         : var(--red);
      font-weight   : 700;
      letter-spacing: 0px;
      color         : #C44042;

      img {
        max-width   : 30px;
        max-height  : 30px;
        margin-right: 8px;
      }
    }
  }

  .btnContinue {
    margin-top: 80px;
    width     : 462px;
  }
}