@import "../../../../css/_mixins.scss";

.container {
  display        : flex;
  justify-content: center;
  align-items    : center;
  flex-direction : column;
  width          : 100%;
  min-height     : 75vh;
  padding        : 16px;
  text-align     : center;

  .title {
    text-align : center;
    color      : var(--red);
    font-size  : 45px;
    font-style : normal;
    font-weight: 700;

    @include tablet {
      font-size  : 36px;
      line-height: 48px;
    }
  }

  .btnLogin {
    margin-top : 16px;
    font-size  : 25px;
    font-weight: 700;
    color: #c44042;
    text-decoration: underline;
    cursor: pointer;
  }

  .content {
    max-width : 730px;
    margin-top: 16px;

    .row {
      display   : flex;
      margin-top: 16px;

      @include tablet {
        display: block;
      }

      input {
        width         : 100%;
        padding       : 14px 24px;
        border        : 1px solid var(--gray);
        border-radius : 35px;
        font-size     : 25px;
        color         : var(--gray);
        letter-spacing: 0px;
        text-align    : center;
        color         : #707070;

        &:not(:first-child) {
          margin-left: 16px;

          @include tablet {
            margin-top : 16px;
            margin-left: 0;
          }
        }
      }

      .stateSelector {
        width         : 100%;
        padding       : 14px 24px;
        margin-left   : 16px;
        border        : 1px solid var(--gray);
        border-radius : 35px;
        font-size     : 25px;
        color         : var(--gray);
        letter-spacing: 0px;
        text-align    : center;
        color         : #707070;

        @include tablet {
          margin-top : 16px;
          margin-left: 0;
        }
      }
    }
  }

  .btnContinue {
    margin-top: 80px;
    width     : 20%;

    @include mobile {
      width: 100%;
    }
  }
}