@import "../../../../css/_mixins.scss";

.container {
  display        : flex;
  justify-content: center;
  align-items    : center;
  flex-direction : column;
  width          : 100%;
  min-height     : 75vh;
  padding        : 16px;
  text-align     : center;

  .title {
    text-align : center;
    color      : var(--red);
    font-size  : 45px;
    font-style : normal;
    font-weight: 700;

    @include tablet {
      font-size  : 36px;
      line-height: 48px;
    }
  }

  .denominationSelector {
    position     : relative;
    width        : 200px;
    height       : 70px;
    margin-top   : 40px;
    border       : 3px solid var(--red);
    border-radius: 35px;

    .largeFont{
      font-size: 36px;
    }

    .btnToggle {
      width          : 100%;
      padding        : 0 40px;
      line-height    : 68px;
      color          : var(--red);
      text-decoration: none;
      font-weight    : bold;
      text-overflow  : ellipsis;
      overflow       : hidden;
      white-space    : nowrap;

      @include mobile {
        font-size: 24px;
      }
      &::after {
        position : absolute;
        top      : 50%;
        right    : 16px;
        transform: translateY(-50%);
      }
    }
    .content {
      width     : 100%;
      max-height: 500px;
      overflow  : auto;
      left      : 0;
      inset     : 0;
      transform : none;
    }
  }

  .denominationEditor {
    display        : flex;
    justify-content: center;
    align-items    : center;
    margin-top     : 40px;
    color          : var(--red);
    font-size      : 49px;


    .inputDenomination {
      width: 30%;
      height: 73px;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border: 1px solid #707070;
      border-radius: 25px;
      color: var(--gray);
      text-align: center;
      font-size: 49px;
      letter-spacing: 0px;
    }
  }
.label{
  color: var(--red);
  font-size: 20px;
  margin-left: 20px;
}
  .numberEditor {
    display        : flex;
    align-items    : center;
    justify-content: space-between;
    vertical-align: middle;
    gap: 20px;
    margin-top     : 40px;

    @include mobile {
      display: block;
    }

    .inputNumber {
      width         : 150px;
      height        : 65px;
      border        : 2px solid var(--gray);
      background    : #FFFFFF 0% 0% no-repeat padding-box;
      border-radius : 35px;
      color         : var(--gray);
      text-align    : center;
      font-size     : 49px;
      letter-spacing: 0px;
    }
    .lblStock {
      margin-left   : 16px;
      font-size     : 20px;
      font-style    : italic;
      color         : var(--red);
      letter-spacing: -0.46px;
      font-weight   : 700;
    }
  }

  .btnContinue {
    margin-top: 44px;
    width     : 20%;

    @include mobile {
      width: 100%;
    }
  }
}