@import "../../../css/_mixins.scss";

.content {
  max-width : 730px;
  margin-top: 16px;

  .row {
    display   : flex;
    margin-top: 16px;

    @include tablet {
      display: block;
    }

    input {
      width         : 100%;
      padding       : 8px 16px;
      border        : 1px solid var(--gray);
      border-radius : 35px;
      font-size     : 20px;
      color         : var(--gray);
      letter-spacing: 0px;
      text-align    : center;
      color         : #707070;

      &:not(:first-child) {
        margin-left: 16px;

        @include tablet {
          margin-top : 16px;
          margin-left: 0;
        }
      }
    }

    textarea {
      width         : 100%;
      padding       : 8px 16px;
      border        : 1px solid var(--gray);
      border-radius : 24px;
      font-size     : 20px;
      color         : var(--gray);
      letter-spacing: 0px;
      color         : #707070;
    }

    .stateSelector {
      width         : 100%;
      padding       : 8px 16px;
      margin-left   : 16px;
      border        : 1px solid var(--gray);
      border-radius : 35px;
      font-size     : 20px;
      color         : var(--gray);
      letter-spacing: 0px;
      text-align    : center;
      color         : #707070;

      @include tablet {
        margin-top : 16px;
        margin-left: 0;
      }
    }
  }

  .checkbox {
    margin-top: 16px;
  }
}

.footer {
  display: flex;

  button {
    height     : 50px;
    min-width  : auto;
    font-size  : 20px;
    margin-left: 16px;
  }
}