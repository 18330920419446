@import "../../../css/_mixins.scss";

.container {
  .stepLabel {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 35px;
    line-height: 32px;
    color: var(--white);
    letter-spacing: -0.78px;

    @include tablet {
      display: block;
      text-align: center;
    }
  }
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    min-height: 75vh;
    padding: 16px;
    background: url(/images/new-assets/bg-onboarding.png) no-repeat;
    background-size: cover;
    background-position: center;
    text-align: center;

    .title {
      text-align: center;
      color: var(--red);
      font-size: 50px;
      font-style: normal;
      font-weight: 700;

      @include tablet {
        font-size: 50px;
        line-height: 50px;
      }

      @include mobile {
        font-size: 36px;
        line-height: 48px;
      }
    }

    .typeSelector {
      display: flex;
      justify-content: center;
      margin-top: 44px;

      button:not(:first-child) {
        margin-left: 32px;
      }

      @include tablet {
        display: block;

        button {
          width: 385px;

          &:not(:first-child) {
            margin-top: 24px;
            margin-left: 0;
          }

          @include mobile {
            width: 100%;
          }
        }
      }
    }

    .btnContinue {
      width: 462px;
      margin-top: 72px;

      @include tablet {
        width: 385px;
        margin-top: 44px;

        @include mobile {
          width: 100%;
        }
      }
    }
  }
}
