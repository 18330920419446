@import '../../../css/_mixins.scss';

.container {
  .content {
    display: inline-table;
    width: 100%;
    height: 75vh;
    padding: 16px;
    background-size: cover;
    background: url(/images/new-assets/bg-onboarding.png) no-repeat center;
    text-align: center;

    .infoBtn {
      color: #aa0505;
      left: -10px;
      vertical-align: middle;
      top: 20px;
      position: relative;

      @media (max-width: 1080px) {
        display: none;
      }
    }

    .infoLabel {
      color: gray;

      @media (max-width: 1080px) {
        white-space: nowrap;
        justify-content: center;
        display: flex;
        position: relative;
        right: 50%;
        margin-left: 100%;
      }
    }

    .title {
      text-align: center;
      color: var(--red);
      font-size: 50px;
      font-style: normal;
      padding-top: 90px;
      font-weight: 700;

      @include tablet {
        font-size: 50px;
        line-height: 50px;
      }

      @include mobile {
        font-size: 36px;
        line-height: 48px;
      }
    }

    .typeSelector {
      display: flex;
      justify-content: center;
      gap: 30px;
      margin-top: 44px;
      width: 100%;

      @media (max-width: 1080px) {
        flex-direction: column;
        gap: 5px;
        align-items: center;
      }

      Button, .divCustomCards {
        @media (max-width: 1080px) {
          font-size: 120%;
          margin: 3px;
          width: 100%;
          max-width:500px;
        }
      }

      button {
        @include mobile {
          width: 100%;
          i {
            display: none;
          }
        }
      }
    }

    .btnContinue {
      width: 20%;
      padding: 3px;
      margin-top: 72px;
      margin-bottom: 20px;

      @include tablet {
        margin-top: 44px;

        @include mobile {
          width: 100%;
        }
      }
    }
  }
}
