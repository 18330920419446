@import "../../../css/_mixins.scss";

.footer {
    color: white;
    text-align: center;
    background-color: var(--red);
    font-style: italic;
    font-weight: 700;
    font-size: 22px;
    padding: 20px;
}
