@import "../../../css/_mixins.scss";

.container {
  display   : flex;
  width     : 100%;
  min-height: 75vh;
  text-align: center;

  @include small-desktop {
    max-width : none;
    width     : 100%;
    padding   : 0 80px;
    margin-top: 40px;
  }

  @media (max-width: 767px) {
    padding: 0 16px;
    overflow-x: hidden;
    align-items: center;
    min-height: auto;
  }

  .content {
    flex           : 1;
    display        : flex;
    justify-content: center;
    align-items    : center;
    flex-direction : column;
    padding        : 0 60px;
    
    @media (max-width: 767px) {
      padding: 0 0px;
    }

    .title {
      margin-top : 40px;
      line-height: 57px;
      text-align : center;
      color      : var(--red);
      font-size  : 60px;
      font-style : normal;
      font-weight: 700;

      @include tablet {
        font-size  : 36px;
        line-height: 48px;
      }
      @media (max-width: 767px) {
        font-size: 38px;
        margin-top: 20px;
        margin-left: 0px;
        line-height: normal;
      }
    }

    .description {
      margin-top    : 24px;
      font-size     : 24px;
      line-height   : 34px;
      color         : var(--gray);
      font-weight   : bold;
      text-align    : center;
      letter-spacing: -0.7px;

      @include tablet {
        font-size: 26px;
      }
      @media (max-width: 767px) {
        font-size: 22px;
        margin-bottom: 100px;
      }
    }

    .methodWrapper {
      display   : flex;
      margin-top: 40px;
      @media (max-width: 767px) {
        flex-direction: column;
      }
      button {

        &:not(:first-child) {
          margin-left: 20px;
        }

        i {
          margin-left: 16px;
        }
        @media (max-width: 767px) {
          font-size: x-large;
          font-weight: bold;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          min-width: 100px;
          max-width: 330px;
          white-space: nowrap;
        }
      }
    }

    .editContent {
      display: flex;
      align-items: center;
      margin-top: 40px;

      .lblEdit {
        font-size: 24px;
        font-weight: bold;
        color: var(--red);
      }

      .btnStepBack {
        position        : relative;
        width           : 205px;
        height          : 41px;
        margin-left: 24px;
        color           : var(--text-gray);
        font-size       : 17px;
        font-weight     : bold;
        border          : 3px solid var(--text-gray);
        background-color: var(--white);
        border-radius   : 35px;

        i {
          position : absolute;
          top      : 50%;
          right    : 16px;
          transform: translateY(-50%);
          font-size: 20px;
        }
      }
    }
  }
}