@import "../../../../css/_mixins.scss";

.container {
  display        : flex;
  justify-content: center;
  align-items    : center;
  flex-direction : column;
  width          : 100%;
  min-height     : 75vh;
  padding        : 16px;
  text-align     : center;

  .title {
    text-align : center;
    color      : var(--red);
    font-size  : 45px;
    font-style : normal;
    font-weight: 700;

    @include small-desktop {
      font-size  : 36px;
      line-height: 36px;
    }
  }

  .typeMethod {
    display        : flex;
    justify-content: center;
    flex-wrap      : wrap;
    margin         : 16px 0 0 -32px;

    button {
      margin: 24px 0 0 32px;

      @include small-desktop {
        width      : 320px;
        height     : 70px;
        flex-shrink: 0;
        font-size  : 26px;
        padding    : 0;
        margin-top : 24px;
      }
    }
  }

  .btnContinue {
    margin-top: 140px;
    width     : 462px;

    @include mobile {
      width     : 100%;
      margin-top: 60px;
    }
  }
}