.container {
  
  width: 100%;

  h1 {
    font-weight: bold;
    font-size  : 42px;
    line-height: 56px;
    color      : white;
    max-width  : 90%;

    span {
      font-size  : 100px;
      line-height: 100px;
    }
  }

  h2 {
    margin-top : 32px;
    font-weight: bold;
    font-size  : 24px;
    line-height: 38px;
    color      : white;
    max-width  : 90%;
  }

  .carousel {
    display: flex;
    height : 650px;

    button {
      margin-top: 32px;
    }

    &.carousel1 {
      background-color: var(--red);
      height : 650px;

      .left {
        position        : relative;
        width           : 45%;
        background-color: var(--red);
        box-shadow      : inset 0 7px 9px -7px rgba(0, 0, 0, 0.4);

        img {
          position : absolute;
          left     : 20%;
          top      : 50%;
          z-index  : 10;
          max-width: 100%;
          transform: translateY(-50%);
        }
      }

      .right {
        display         : flex;
        flex-direction  : column;
        justify-content : center;
        align-items     : flex-start;
        width           : 55%;
        padding-left    : 15%;
        background-color: var(--yellow);
        clip-path       : polygon(0 0, 100% 0%, 100% 100%, 25% 100%);
      }
    }

    &.carousel2 {
      background-color: var(--yellow);
      height : 650px;

      .left {
        width    : 55%;
        clip-path: polygon(0 0, 75% 0, 100% 100%, 0 100%);

        img {
          width     : 100%;
          height    : 100%;
          object-fit: cover;
        }
      }

      .right {
        display        : flex;
        flex-direction : column;
        justify-content: center;
        align-items    : flex-start;
        width          : 45%;
        padding-left   : 5%;
      }
    }

    &.carousel3 {
      background-color: var(--yellow);
      height : 650px;

      .left {
        position        : relative;
        width           : 100%;
        background-color: var(--yellow);

        img {
          width     : 100%;
          height    : 100%;
          object-fit: contain;
        }
      }

      .right {
        display         : flex;
        flex-direction  : column;
        justify-content : center;
        align-items     : flex-start;
        background-color: var(--red);
        clip-path       : polygon(0 0, 100% 0%, 100% 100%, 25% 100%);
        box-shadow      : inset 0 7px 9px -7px rgba(0, 0, 0, 0.4);
        padding-left    : 15%;
      }
    }
  }
}