.container {
  display: flex;
  width: 100%;

  @media screen and (max-width: 1080px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .left {
    width: 50%;
    object-fit: contain;
    @media screen and (max-width: 1080px) {
      width: 100%;
    }
  }

  .right {
    width: 50%;
    padding: 80px;
    @media screen and (max-width: 1080px) {
      width: 100%;
    }

    .lblTitle {
      font-size: 24px;
      font-weight: bold;
      color: var(--yellow);
      @media screen and (max-width: 1080px) {
        display: none;
      }
    }

    .title {
      font-size: 38px;
      font-weight: bold;
      line-height: 48px;
      color: var(--red);
    }

    .subTitle {
      margin-top: 16px;
      font-size: 24px;
      font-weight: bold;
      color: var(--gray);
    }

    .description {
      max-width: 650px;
      margin-top: 24px;
      color: var(--gray);
    }

    .actions {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      button {
        min-width: auto;
        margin-top: 32px;
        margin-right: 24px;
        width:250px;
      }
    }
  }
}
