@import "../../../../css/_mixins.scss";

.container {
  display        : flex;
  justify-content: center;
  align-items    : center;
  flex-direction : column;
  width          : 100%;
  min-height     : 75vh;
  padding        : 16px;
  text-align     : center;

  .title {
    text-align : center;
    color      : var(--red);
    font-size  : 45px;
    font-style : normal;
    font-weight: 700;

    @include tablet {
      font-size  : 36px;
      line-height: 48px;
    }
  }

  .amountEditor {
    display        : flex;
    justify-content: center;
    align-items    : center;
    margin-top     : 44px;

    @include mobile {
      display: block;
    }

    .inputAmount {
      width         : 230px;
      height        : 80px;
      border        : 1px solid var(--gray);
      background    : #FFFFFF;
      border        : 1px solid #707070;
      border-radius : 30px;
      color         : var(--gray);
      text-align    : center;
      font-size     : 50px;
      letter-spacing: 0px;
    }

    .lblAmount {
      margin-left: 24px;
      font-size  : 23px;
      line-height: 28px;
      color      : var(--gray);
      text-align : left;
      font-weight: 500;

      @include mobile {
        margin    : 16px 0 0 0;
        text-align: center;
      }
    }
  }

  .amountResult {
    margin-top : 40px;
    font-size  : 48px;
    color      : var(--red);
    text-align : center;
    font-weight: 700;

    .lblResult {
      margin-top: 10px;
      font-size : 20px;
    }
  }

  .btnContinue {
    margin-top: 44px;
    width     : 400px;

    @include mobile {
      width: 100%;
    }
  }
}