.container {
  text-align: center;

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
  }

  .tipDonationContent {
    position: relative;
    padding: 30px;
    flex-shrink: 0;
    text-align: left;
    background-color: var(--concrete);

    @media (max-width: 900px) {
      width: 100vw;
      padding: 20px 60px;
      text-align: center;
      align-items: center;
      display: flex;
      flex-direction: column;
    }

    i {
      position: absolute;
      top: 35px;
      right: 20px;
      color: var(--azure);
      font-size: 24px;
    }

    img {
      max-width: 100%;
    }

    .donationLabel {
      margin-top: 26px;
      font-size: 26px;
      line-height: 39px;
      font-weight: 700;
      color: var(--red);
    }

    .donationDescription {
      margin-top: 10px;
      color: var(--gray);
      font-size: 14px;
      line-height: 28px;
      font-weight: 1000;
      letter-spacing: 0px;
    }

    .amountLabel {
      margin-top: 24px;
      color: var(--gray);
      font-size: 20px;
      font-weight: 1000;

      @media (max-width: 900px) {
        margin-top: 4px;
      }
    }

    .amountWrapper {
      display: flex;
      align-items: center;
      margin-top: 24px;
      font-size: 39px;
      letter-spacing: 0px;
      width: 60%;
      color: var(--azure);

      @media (max-width: 900px) {
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 30px;
      }

      input {
        margin-right: 20px;
        border: 1px solid var(--gray);
        border-radius: 35px;
        color: var(--gray);
        text-align: center;
        font-size: 54px;
        letter-spacing: 0px;
        width: 160px;
        height: 60px;
      }
    }
  }

  .content {
    display: flex;
    padding: 60px;
    margin-top: 2%;
    align-items: center;
    flex-direction: column;

    @media (max-width: 900px) {
      width: 100vw;
      z-index: 2;
      display: inline-block;
      top: 50%;
    }

    .title {
      width: 100%;
      letter-spacing: -1.14px;
      text-align: center;
      color: var(--red);
      font-size: 205%;
      font-style: normal;
      font-weight: 700;
      margin-bottom: 30px;
    }

    .btnContinue {
      margin-top: 28px;
      //width     : 20%;
    }
  }
}