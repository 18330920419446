@import "../../../../css/_mixins.scss";

.yesBtn, .noBtn {
  border: 25px;
  margin-inline: 7px;
  padding-inline: 22px;
  color: white;
  font-size: 17px;
  float: right;
  height: 50px;
  border-radius: 25px;
  font-weight: bold;
  margin: 10px;
}
.yesBtn {
  background-color: var(--yellow);
}

.noBtn {
  background-color: var(--red);
}

.container {
  .content {
    display: flex;
    justify-content: center;
    gap: 2vh;
    align-items: center;
    flex-direction: column;
    width: 100%;
    min-height: 75vh;
    padding: 16px;
    background: url(/images/new-assets/bg-onboarding.png) no-repeat;
    background-size: cover;
    background-position: center;
    text-align: center;

    .divAddToDonation {
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
      color: var(--red);
      justify-content: space-around;
      align-items: center;
      background-color: #ddd;
      margin: 15px auto;
      padding: 10px 20px;
      border-radius: 20px;

      .addToDonationBtn {
        font-weight: bold;
        color: #fff;
        font-size: 15px;
        background-color: var(--red);
        cursor: pointer;
        padding: 3px 10px;
        border-radius: 20px;
      }

      .amountInput {
        color: var(--red);
        border: 1px solid var(--red);
        border-radius: 10px;
        width: 60px;
        font-size: 19px;
        text-align: center;
      }
    }

    .caption {
      color: darkgray;
      font-weight: bold;
      font-size: 26px;
    }

    .title {
      max-width: 80%;
      text-align: center;
      color: var(--red);
      font-size: 40px;
      font-style: normal;
      font-weight: 700;

      @include tablet {
        font-size: 24px;
      }

      .imgLogo {
        max-width: 300px;
      }
    }

    .imgCard {
      display: block;
      margin-top: 32px;
      margin-bottom: 20px;
      max-width: 300px;
    }

    .multiInfo {
      margin-top: 40px;
      max-width: 540px;
      color: var(--gray);
      text-align: center;
      font-size: 22px;
      line-height: 32px;
      font-weight: 600;
      letter-spacing: 0px;
    }

    .multiInputWrapper {
      position: relative;
      max-width: 400px;
      padding: 0 56px;
      margin-top: 16px;

      .multiInputCode {
        width: 100%;
        height: 70px;
        text-align: center;
        font-size: 32px;
        color: var(--red);
        text-align: center;
        letter-spacing: -1px;
        border: 3px solid var(--red);
        border-radius: 35px;
        text-transform: uppercase;
      }

      i {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        color: var(--red);
        font-size: 32px;
        cursor: pointer;
      }
    }

    .errorMessage{
      max-width: 80%;
      text-align: center;
      color: var(--red);
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
    }

    .redemptionDetail {
      display: flex;
      margin-top: 12px;

      @include tablet {
        display: block;
      }

      .inputWrapper {
        &:not(:first-child) {
          margin-left: 24px;

          @include tablet {
            margin: 0;
          }
        }

        .inputCode {
          width: 300px;
          height: 70px;
          text-align: center;
          font-size: 40px;
          color: var(--red);
          text-align: center;
          font-weight: bold;
          letter-spacing: -1px;
          border: 3px solid var(--red);
          border-radius: 30px;
          text-transform: uppercase;

          @include tablet {
            height: 56px;
            font-size: 32px;
          }
        }

        .linkChangeCode {
          margin-top: 5px;
          color: var(--primary);
          font-size: 18px;
          font-weight: bold;
          letter-spacing: 0px;
          text-decoration: none !important;
          cursor: pointer !important;

          @include tablet {
            margin: 0;
            font-size: 24px;
          }
        }

        .inputEmail {
          width: 300px;
          height: 70px;
          padding: 7px;
          letter-spacing: 0;
          color: var(--red);
          letter-spacing: 0px;
          text-align: center;
          border: 2px solid var(--red);
          border-radius: 30px;

          @include tablet {
            margin-top: 8px;
            height: 56px;
            font-size: 32px;
          }
        }

        .lblEmail {
          margin-top: 10px;
          color: var(--red);
          text-align: center;
          font-size: 18px;
          font-style: italic;
          letter-spacing: 0px;
        }
      }
    }

    .termsWrapper {
      display: flex;
      align-items: center;
      margin-top: 40px;

      @include tablet {
        margin-left: 24px;
      }

      .termsRadio {
        width: 24px;
        height: 24px;
        border: 2px solid var(--red);
      }

      .lblTerms {
        display: block;
        max-width: 630px;
        margin-left: 16px;
        font-size: 16px;
        line-height: 24px;
        text-align: left;
        color: var(--gray);
        letter-spacing: 0px;
      }
    }

    .lblError {
      margin-top: 40px;
      color: var(--red);
      text-align: center;
      font-size: 18px;
      font-weight: bold;
    }
  }
}
