@import "../../../css/_mixins.scss";

.container {
  .stepLabel {
    display: flex;
    margin-left: 40vw;
    margin-right: 10px;
    justify-content: space-between;
    align-items: center;
    font-size: 35px;
    line-height: 32px;
    color: var(--white);
    letter-spacing: -0.78px;
    white-space: nowrap;

    .checkoutLabel{
      margin-inline: 10px;
      font-size: 16px;
      align-self: flex-end;
      position: relative;
      margin-bottom: -5px;
    }
   @media (max-width: 600px) {
     margin-left: 0;
   }
    @include tablet {
      //display   : block;
      text-align: center;
    }
  }

  .content {
    position      : relative;
    padding-bottom: 100px;


    .btnStepBack {
      position        : absolute;
      bottom          : 40px;
      right           : 60px;
      width           : 205px;
      height          : 41px;
      color           : var(--text-gray);
      font-size       : 17px;
      font-weight     : bold;
      border          : 3px solid var(--text-gray);
      background-color: var(--white);
      border-radius   : 35px;

      i {
        position : absolute;
        top      : 50%;
        right    : 16px;
        transform: translateY(-50%);
        font-size: 20px;
      }
    }
  }
}