@import "../../../../css/_mixins.scss";
.container {
  display: flex;
  width: 100%;
  max-height: 65vh;

  .img {
    flex: 0 0 65%;
    object-fit: cover;

    @media (min-width: 768px) and (max-width: 1024px) {
      width: 53vw;
      flex: 0 0 59%;
    }

    @media (max-width: 767px) {
      display: none;
    }
  }
  .content {
    align-items: center;
    color: var(--red);
    display: flex;
    flex: 0 0 35%;
    flex-direction: column;
    font-weight: 700;
    gap: 30px;
    justify-content: center;
    margin-bottom: auto;
    margin-top: auto;
    padding-bottom: 10px;
    margin: 0 0 13px 0;
    padding: 0px 20px;
    overflow-y: scroll;
    text-align: center;

    @media (min-width: 768px) and (max-width: 1024px) {
      flex: 0 0 42%;
    }
    @media (max-width: 767px) {
      flex: content;
      padding: 20px;
      justify-content: flex-start;
      gap: 15px;
      min-height: auto;
      overflow: visible;
    }

    .title {
      font-size: 190%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 14px;

      .donateIcon {
        display: none;
      }
      @media (max-width: 767px) {
        .donateIcon {
          display: block;
        }
      }

      .preselCharity {
        font-weight: bold;

        .donatingTo {
          color: black;
          font-size: medium;
        }
        .charityName {
          font-size: large;
        }
      }
    }
    .title:hover .tooltip {
      display: block;
    }

    .inputAmount {
      border: solid 2px gray;
      border-radius: 20px;
      height: 55px;
      text-align: center;
      padding: 5px;
      box-sizing: border-box;
      max-width: 40%;
      font-size: 2.5rem;
      color: gray;
      text-indent: 10px;
      width: 100%;
      line-height: normal;
    }
    .amountLbl {
      display: block;
      font-size: 190%;

      .tag {
        font-size: medium;
      }
    }
    .continueBtn {
      background: #0000 linear-gradient(105deg, #f2b74e, #e89d18) 0 0 no-repeat padding-box;
      border: none;
      border-radius: 35px;
      box-shadow: 2px 3px 40px rgba(0, 0, 0, 0.133);
      color: #fff;
      font-size: 120%;
      font-weight: 700;
      height: 48px;
      opacity: 1;
      width: 78%;
      margin-bottom: 20px;
    }
    .continueBtn:disabled {
      background: #e2d682;
      cursor: not-allowed;
    }
    .checkbox {
      display: block;
      font-size: small;
    }
    .changePreselCharity {
      color: var(--yellow);
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
