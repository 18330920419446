.modalHeader {
  h5 {
    width:100%;    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: flex-start;
  }
  button{
    font-size: 0.6em;
    padding: 0 10px;
    background-color: var(--red);
  }
}
