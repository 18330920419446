@import "../../../../css/_mixins.scss";

.customContent {
  padding: 28px 40px;
  background-color: var(--red);

  @include small-desktop {
    padding: 24px 32px;
  }

  @include mobile {
    padding: 24px 16px;
  }
}

.minimizedContent {
  display: flex;
  background-color: var(--red);
  padding: 17px;
  justify-content: center;
  align-items: center;
  gap: 15px;

  .inputRedeemCode {
    width: 230px;
    height: 50px;
    text-align: center;
    font-size: 18px;
    border: none;
    box-shadow: none;
    text-transform: uppercase;

    @media screen and (max-width:1080px) {
      height: 47px;
      max-width: 45%;
      font-size: calc(45% + 1vw);
    }
  }

  .btnRedeemMinimized {
    background-color: transparent;
    border: 2px solid #f2f2f2;
    border-radius: 35px;
    padding: 12px;
    color: var(--white);
    font-size: 14px;
    font-weight: 700;
    text-wrap: nowrap;
  }
}
