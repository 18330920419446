@import "../../../css/_mixins.scss";
.bar {
  height: 60px;
  margin-bottom: 45px;
}
.lblMethod {
  margin-top: 32px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  line-height: 36px;
  letter-spacing: -0.62px;
}
.socialLinksContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  flex-wrap: wrap;
  text-align: center;
  font-size: 20px;
  line-height: 40px;
  font-weight: bold;
  letter-spacing: -0.6px;
  .row {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .socialLinks {
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    .btnShare {
      margin-left: 24px;
      cursor: pointer;

      i {
        font-size: 35px;
      }
    }
  }
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  z-index: 5;
  left: 20%;
}
.infoTooltip {
  background-color: #fff;
  border: 1px solid #000;
  font-size: 16px;
  letter-spacing: -0.56px;
  padding: 3px 7px;
  top: 29%;
  white-space: nowrap;
  position: absolute;
}
.infoIcon {
  cursor: pointer;
  position: absolute;
}
.infoContent {
  background-color: var(--concrete);
  padding: 20px 10px 10px;
  width: 400px;
}

.btnWrapper {
  display: flex;
  gap: 15px;
  width: 70%;
  padding: 15px 60px;
  justify-content: center;
  @media (max-width: 600px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  Button {
    height: 60px;
    min-width: 230px;
    font-size: 20px;
    padding: 0 30px;

    i {
      margin-left: 16px;
    }
  }
}
.selCharities {
  max-width: 60%;
  white-space: wrap;
}
.container {
  position: relative;
  width: 100%;
  min-height: 75vh;
  text-align: center;
  bottom: 100px;

  .customInfo {
    padding-top: 30px;
    // display: flex;
    // align-items: flex-start;
    // justify-content: space-between;
    // padding: 10px;
    height: 200px;
    // position: fixed;
    .caption {
      margin: 15px 50px 0 0;
      font-size: 28px;
      font-weight: bold;
    }
    .logo {
      margin: 0 0 10px 50px;
      height: 60px;
    }
  }
  .content {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 1%;
    padding: 55px 16px;

    .btn {
      text-align: center;
      width: 30%;
    }
    .title {
      margin-top: 40px;
      text-align: center;
      color: var(--red);
      font-size: 70px;
      font-style: normal;
      font-weight: 700;

      @include tablet {
        font-size: 36px;
        line-height: 48px;
      }
    }

    .description {
      margin-top: 26px;
      font-size: 20px;
      line-height: 34px;
      color: var(--gray);
      font-weight: bold;
      text-align: center;
      letter-spacing: -0.7px;

      span {
        font-size: 24px;
        color: var(--red);
      }

      @include tablet {
        font-size: 26px;
      }
    }
    .purchaseLink {
      font-size: 18px;
      font-weight: bold;
      padding: 17px 30px;
      border-radius: 50px;
      margin: 40px;
      display: block;
      text-decoration: none;

      @media only screen and (max-width: 550px) {
        padding: 1px 30px;
      }
    }
  }
}
