@import "../../../css/_mixins.scss";

.container {
  .stepLabel {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 35px;
    line-height: 32px;
    color: var(--white);
    letter-spacing: -0.78px;

    @include tablet {
      display: block;
      text-align: center;
    }
  }

  .content {
    padding-bottom: 3vh;

    .divBackButton {
      display: flex;
      justify-content: flex-end;
      padding-right: 4vw;
      align-items: center;
      max-width: 100%;
      .btnStepBack {
        padding: 5px 30px;
        margin-top: -100px;
        color: var(--text-gray);
        font-size: 17px;
        font-weight: bold;
        border: 3px solid var(--text-gray);
        background-color: var(--white);
        border-radius: 35px;

        i {
          font-size: 20px;
          display: inline-block;
          margin-left: 15px;
        }
      }
      @media screen and (max-width:1080px) {
        padding: initial;
        justify-content: center;
        margin-top: 50px;
        .btnStepBack {
          margin-top: 0;
        }
      }
    }
  }
}
