@import "../../../../css/_mixins.scss";

.container {
  display        : flex;
  justify-content: center;
  align-items    : center;
  flex-direction : column;
  width          : 100%;
  min-height     : 75vh;
  padding        : 30px 16px;
  text-align     : center;


  .title {
    text-align : center;
    color      : var(--red);
    font-size  : 45px;
    font-style : normal;
    font-weight: 700;

    @include tablet {
      font-size  : 36px;
      line-height: 48px;
    }
  }
.shippingTypesInfo{
  @media (max-width: 900px) {
    width: 100%;
  }
}
  .description {
    max-width     : 840px;
    margin-top    : 16px;
    font-size     : 20px;
    line-height   : 32px;
    font-weight   : 700;
    color         : var(--gray);
    text-align    : center;
    letter-spacing: 0px;
  }

  .content {
    margin-top : 30px;
    display: flex;
    flex-direction: row;
    gap: 60px;

    @media (max-width: 900px) {
flex-direction: column;
    }
    .selectorWrapper {
      padding      : 24px 32px;
      color: var(--gray);
      border       : 1px solid var(--gray);
      border-radius: 35px;
      text-align   : left;

      .shippingOption{
        margin: 8px 0;
      }
    }
.deliveryInfo{
  padding: 50px;
  color: gray;
  display: flex;
  flex-direction: column;

  .deliveryDate{
    margin-top: 20px;
    color: var(--red);
    font-size: 26px;
    font-weight: bold;
  }
}
    .btnContinue {
      width     : 20%;

      @include mobile {
        width: 100%;
      }
    }
  }
}