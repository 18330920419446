@import "../../../../css/_mixins.scss";

.container {
  display        : flex;
  justify-content: center;
  align-items    : center;
  flex-direction : column;
  width          : 100%;
  min-height     : 75vh;
  padding        : 16px;
  text-align     : center;

  .title {
    text-align : center;
    color      : var(--red);
    font-size  : 45px;
    font-style : normal;
    font-weight: 700;

    @include tablet {
      font-size  : 36px;
      line-height: 48px;
    }
  }

  .typeSelector {
    display        : flex;
    flex-wrap      : wrap;
    justify-content: center;
    align-items    : center;
    width          : 100%;
    margin         : 60px 0 0 -16px;

    .imgCard {
      width        : 250px;
      border       : 2px solid #FFFFFF;
      border-radius: 14px;
      overflow     : hidden;
      margin       : 16px 0 0 16px;
      cursor       : pointer;

      &.active {
        box-shadow: 3px 3px 15px #0000004B;
      }

      img {
        width         : 100%;
        object-fit    : contain;
        pointer-events: none;
      }
    }
  }

  .checkMultiColor {
    margin-top: 24px;
  }

  .btnContinue {
    margin-top: 72px;
    width     : 20%;

    @include mobile {
      width: 100%;
    }
  }
}