.container {
  padding: 16px 0;

  .customRedemptionPageModalTitle {
    font-size  : 32px;
    font-weight: bold;
    line-height: 40px;
    color      : var(--yellow);
  }

  .customRedemptionPageModalDesc {
    margin-top: 16px;
    font-size : 16px;
    color: var(--gray);
  }
}