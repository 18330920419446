.container {
  width          : 50%;

  .trigger {
    display        : flex;
    justify-content: center;
    align-items    : center;
    height         : 60px;
    color          : white;
    font-size      : 20px;
    font-weight    : 600;
    background     : var(--gray);
    box-shadow     : 2px 3px 40px #00000022;
    border-radius  : 25px;
    @media (max-width: 767px) {
      width: 100px;
      padding-right  : 10px;
      padding-left  : 10px;
      font-size: 16px;
      position: absolute;
    }
    
    cursor: pointer;
  
    i {
      margin-left: 24px;
      @media (max-width: 767px) {
        margin-left: 0;
        padding-right: 11px;
      }
    }
  }
  
  .pickerWrapper {
    position: absolute;
    z-index : 2;
  
    .backdrop {
      position: fixed;
      inset   : 0;
    }
  }
  
}