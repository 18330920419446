@import "../../../../css/_mixins.scss";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 75vh;
  padding: 0 16px;
  text-align: center;

  .title {
    text-align: center;
    color: var(--red);
    font-size: 45px;
    font-style: normal;
    font-weight: 700;

    @include mobile {
      font-size: 36px;
    }
  }

  .typeSelector {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 44px;
    margin-bottom: 25px;
    gap: 30px;

    @media screen and (max-width:1080px) {
      flex-direction: column;
      max-width: 100%;
      button {        
        max-width: 100%;
        font-size: 4.5vw;   
      }
    }
  }

  .btnUpload {
    position: relative;
    width: 320px;
    margin-top: 36px;
    font-size: 30px;

    i {
      position: absolute;
      top: 50%;
      right: 30px;
      transform: translateY(-50%);
    }
  }

  .logoDetail {
    margin-top: 36px;

    .fileLabel {
      font-size: 26px;
      line-height: 32px;
      font-weight: bold;
      color: var(--gray);
      letter-spacing: -0.52px;
    }
    img {
      margin-top: 32px;
      max-width: 300px;
      max-height: 300px;
    }

    .actions {
      display: flex;
      justify-content: center;
      margin-top: 16px;

      .btnAction {
        color: var(--red);
        text-decoration: underline;
        cursor: pointer;

        &:not(:first-child) {
          margin-left: 24px;
        }
      }
    }
  }

  .loginLabel {
    margin: 26px;
    width: 600px;
    font-size: 23px;
  }
  .btnLogin {
    font-size: 30px;
    background-color: var(--red);
    font-size: 15px;
    border: 0;
    margin-bottom: 40px;
    padding: 5px !important;
  }

  .uploadLimitation {
    margin-top: 64px;
    font-size: 23px;
    line-height: 28px;
    color: var(--gray);
    text-align: center;
    letter-spacing: -0.46px;

    @include mobile {
      font-size: 20px;
    }
  }

  .btnContinue {
    margin-top: 26px;
    width: 462px;

    @include mobile {
      width: 100%;
    }
  }
  .btnNoLogoContinue {
    margin-top: 15px;
    color: var(--red);
    font-size: 18px;
    text-decoration: underline;
    cursor: pointer;
  }
}
