.container {
  display: flex;
  width: 100%;

  .left {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 80px 0;
    background-color: var(--red);
    font-size: 80px;
    font-weight: bold;
    color: white;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      width: 0;
      height: 0;
      transform: translate(50%, -50%);
      border-style: solid;
      border-width: 50px 0 50px 50px;
      border-color: transparent transparent transparent var(--red);
    }

    span {
      font-size: 32px;
    }
    @media screen and (max-width: 1080px) {
      display: none;
    }
  }

  .right {
    width: 100%;
    padding: 80px;
    @media screen and (max-width: 1080px) {
      padding: 0 65px 40px 65px;
      flex: 1;
    }

    .title {
      font-size: 30px;
      font-weight: bold;
      color: var(--red);
      @media screen and (max-width: 1080px) {
        margin-bottom: 20px;
      }
    }

    .bottomTitle {
      font-size: 33px;
      font-weight: bold;
      color: var(--red);
      margin-top: 20px;
      @media screen and (max-width: 1080px) {
        display: none;
      }
    }
    .description {
      max-width: 600px;
      margin-top: 12px;
      color: var(--gray);
      @media screen and (max-width: 1080px) {
        font-size: 1.25em;
      }

      span {
        color: var(--yellow);
        @media screen and (max-width: 1080px) {
          display: none;
        }
      }
    }
    @media screen and (max-width: 1080px) {
      text-align: center;
    }
  }
}
