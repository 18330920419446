@import "../../../css/_mixins.scss";

.container {
  .stepLabel {
    display        : flex;
    justify-content: center;
    align-items    : center;
    font-size      : 35px;
    line-height    : 32px;
    color          : var(--white);
    letter-spacing : -0.78px;

    @include tablet {
      display   : block;
      text-align: center;
    }
  }

  .content {
    position      : relative;
    padding-bottom: 100px;

    &.noMargin {
      padding: 0;
    }

    .btnStepBack {
      position        : absolute;
      bottom          : 40px;
      right           : 60px;
      width           : 205px;
      height          : 41px;
      color           : var(--text-gray);
      font-size       : 17px;
      font-weight     : bold;
      border          : 3px solid var(--text-gray);
      background-color: var(--white);
      border-radius   : 35px;

      i {
        position : absolute;
        top      : 50%;
        right    : 16px;
        transform: translateY(-50%);
        font-size: 20px;
      }
    }
  }
}