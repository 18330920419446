@import "../../../css/_mixins.scss";

.container {
  display   : flex;
  width     : 100%;
  min-height: 75vh;
  text-align: center;

  @include small-desktop {
    display: block;
  }

  .tipDonationContent {
    position        : relative;
    width           : 400px;
    padding         : 100px 60px;
    flex-shrink     : 0;
    text-align      : left;
    background-color: var(--concrete);

    &.desktop {
      display: block;

      @include small-desktop {
        display: none;
      }
    }

    &.mobile {
      display: none;
      width  : 100%;
      padding: 60px;

      @include small-desktop {
        display    : flex;
        align-items: center;

        .amountInput {
          margin-right: 48px;
        }
      }

      @include tablet {
        flex-direction: column-reverse;
        text-align    : center;
        padding       : 32px 60px;
      }

      @include mobile {
        flex-direction: column-reverse;
        text-align    : center;
        padding       : 32px;
      }
    }

    i {
      position : absolute;
      top      : 35px;
      right    : 20px;
      color    : var(--azure);
      font-size: 24px;

      @include small-desktop {
        top   : auto;
        right : 35px;
        bottom: 35px;
      }
    }

    img {
      max-width: 100%;

      @include tablet {
        max-width: 240px;
      }
    }

    .donationLabel {
      margin-top    : 26px;
      font-size     : 26px;
      line-height   : 32px;
      font-weight   : 700;
      letter-spacing: -0.64px;
      color         : var(--red);

      @include small-desktop {
        margin: 0;
      }
    }

    .donationDescription {
      margin-top    : 16px;
      color         : var(--gray);
      font-size     : 16px;
      line-height   : 28px;
      letter-spacing: 0px;
      color         : #707070;
    }

    .amountLabel {
      margin-top    : 24px;
      color         : var(--gray);
      font-size     : 16px;
      line-height   : 24px;
      font-weight   : 700;
      letter-spacing: -0.4px;
      color         : #707070;

      @include small-desktop {
        margin-top: 24px;
        font-size : 16px;
      }
    }

    .amountWrapper {
      display       : flex;
      align-items   : center;
      margin-top    : 16px;
      font-size     : 30px;
      line-height   : 45px;
      letter-spacing: 0px;
      color         : var(--azure);

      @include small-desktop {
        margin-top: 24px;
      }

      input {
        width         : 200px;
        height        : 70px;
        margin-right  : 32px;
        border        : 1px solid var(--gray);
        border-radius : 30px;
        color         : var(--gray);
        text-align    : center;
        font-size     : 45px;
        letter-spacing: 0px;
        color         : #707070;

        @include small-desktop {
          width    : 200px;
          height   : 65px;
          font-size: 40px;
        }
      }
    }
  }

  .content {
    flex           : 1;
    max-width      : calc(100% - 400px);
    display        : flex;
    justify-content: center;
    align-items    : center;
    flex-direction : column;
    padding        : 0 150px 100px;

    @include small-desktop {
      max-width : none;
      width     : 100%;
      padding   : 40 80px;
      margin-top: 40px;
    }

    @include mobile {
      padding: 16px;
    }

    img {
      max-width: 100%;
    }

    .title {
      letter-spacing: -1.14px;
      text-align    : center;
      color         : var(--red);
      font-size     : 36px;
      font-style    : normal;
      font-weight   : 700;

      @include tablet {
        font-size  : 30px;
        line-height: 48px;
      }
    }

    .description {
      margin-top    : 20px;
      font-size     : 26px;
      line-height   : 38px;
      color         : var(--gray);
      text-align    : center;
      letter-spacing: -0.62px;

      @include tablet {
        font-size: 26px;
      }
    }

    .searchWrapper {
      display        : flex;
      justify-content: center;
      width          : 100%;
      margin-top     : 44px;

      @include medium-desktop {
        display: block;
      }

      .searchInput {
        flex         : 1;
        width        : 100%;
        display      : flex;
        align-items  : center;
        max-width    : 400px;
        height       : 60px;
        padding      : 0 16px;
        border       : 3px solid var(--red);
        border       : 3px solid #C44042;
        border-radius: 30px;
        overflow     : hidden;

        @include medium-desktop {
          margin: auto;
        }

        i {
          color    : var(--red);
          font-size: 25px;
        }

        input {
          flex          : 1;
          font-size     : 26px;
          line-height   : 32px;
          font-weight   : 700;
          text-align    : center;
          letter-spacing: 0px;
          color         : var(--gray);
          border        : none;
        }
      }

      button {
        flex       : 1;
        max-width  : 450px;
        margin-left: 32px;

        @include medium-desktop {
          margin: 24px 0 0 0;
        }

        @include mobile {
          width  : 100%;
          padding: 0;
        }
      }
    }

    .preselectedCharity {
      margin-top: 24px;
      font-size : 20px;

      .charityActions {
        display        : flex;
        justify-content: space-around;

        .btnLink {
          color : var(--red);
          cursor: pointer;
        }
      }
    }

    .btnContinue {
      margin-top: 40px;
      width     : 400px;

      @include mobile {
        width: 100%;
      }
    }
  }
}