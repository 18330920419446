@import "../../../css/_mixins.scss";

.container {
  display: flex;
  width: 100%;
  min-height: 75vh;
  text-align: center;

  @media (max-width: 1080px) {
    flex-direction: column-reverse;
  }

  .donateMethod {
    position: relative;
    width: 400px;
    padding: 60px 50px;
    flex-shrink: 0;
    text-align: left;
    background-color: var(--concrete);

    @media (max-width: 1080px) {
      width: 100vw;
      text-align: center;
    }
    @media (max-width: 400px) {
      width: 100vw;
    }

    i {
      position: absolute;
      top: 35px;
      cursor: pointer;
      right: 20px;
      color: var(--azure);
      font-size: 24px;
    }

    .donationLabel {
      font-size: 22px;
      line-height: 28px;
      font-weight: bold;
      color: var(--yellow);
      text-align: left;
      letter-spacing: -0.56px;
    }

    .donationDescription {
      margin-top: 16px;
      color: var(--gray);
      text-align: left;
      font-size: 15px;
      line-height: 22px;
      letter-spacing: 0px;
    }
  }

  .content {
    flex: 1;
    //max-width: calc(100% - 400px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 15px 150px;

    @include small-desktop {
      max-width: none;
      width: 100%;
      padding: 0 80px;
      margin-top: 40px;
    }

    @include mobile {
      //padding: 0 16px;
      padding: 0 0 20px 20px;
    }

    .confettiImage {
      max-width: 100%;
      margin: 0 0 -65px 0;
      @media (max-width: 1080px) {
        display: none;
      }
    }

    .title {
      text-align: center;
      color: var(--red);
      font-size: 50px;
      font-style: normal;
      font-weight: 700;

      @include tablet {
        font-size: 36px;
        line-height: 48px;
      }
      @media (max-width: 1080px) {
        margin-top: 40px;
      }
    }

    .description {
      margin-top: 26px;
      font-size: 20px;
      line-height: 34px;
      color: var(--gray);
      font-weight: bold;
      text-align: center;
      letter-spacing: -0.7px;

      span {
        font-size: 24px;
        color: var(--red);
      }

      @include tablet {
        font-size: 26px;
      }
    }

    .lblMethod {
      margin-top: 32px;
      color: var(--gray);
      text-align: center;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: -0.62px;
    }

    .methodWrapper {
      display: flex;
      margin-top: 24px;

      @media (max-width: 1080px) {
        flex-direction: column;
        gap: 20px;
      }

      button {
        height: 50px;
        min-width: 230px;
        font-size: 20px;
        padding: 0 30px;
        margin-inline: 8px;

        i {
          margin-left: 16px;
        }
        @media (max-width: 1080px) {
         width:100%;
        }
      }
    }

    .text {
      font-size: 20px;
      color: var(--red);
      font-weight: bold;
      padding-top: 20px;
    }
    .spreadTheJoy {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      color: var(--red);
      text-align: center;
      font-size: 22px;
      line-height: 40px;

      @media (max-width: 1080px) {
        flex-direction: column;
      }
    }

    .sendDonor {
      display: flex;
      align-items: center;
      margin-top: 30px;
      color: var(--yellow);
      text-align: center;
      font-size: 16px;
      line-height: 40px;
      font-weight: bold;
      gap: 8px;
      @media (max-width: 1080px) {
        width: 100%;
        align-items: flex-start;
        line-height: normal;
      }
    }

    .sentDonorEmail {
      text-align: center;
      font-size: 16px;
      line-height: 40px;
      font-weight: bold;
      color: var(--yellow);
      margin-top: 30px;
      @media (max-width: 1080px) {
        margin-top: 0;
        line-height: normal;
      }
    }

    .socialLinks {
      display: flex;
      align-items: center;
      margin-top: 40px;
      color: var(--red);
      text-align: center;
      font-size: 20px;
      line-height: 40px;
      font-weight: bold;
      letter-spacing: -0.6px;

      .btnShare {
        margin-left: 24px;
        cursor: pointer;

        i {
          font-size: 35px;
        }
      }
    }
  }
}
