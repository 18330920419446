@import "../../../../css/_mixins.scss";

.container {
  display: flex;
  width: 100%;
  // max-height: 65vh;

  .img {
    flex: 0 0 66%;
    object-fit: cover;

    @media (min-width: 768px) and (max-width: 1120px) {
      width: 46vw;
      flex: 0 0 46%;
    }
    @media (max-width: 767px) {
      display: none;
    }
  }
  .content {
    // display: flex;
    // gap: 20px;
    // flex: 0 0 36%;
    // justify-content: center;
    // margin-top: auto;
    // margin-bottom: auto;
    // padding-left: 10px;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // text-align: center;
    // font-weight: bold;
    // color: var(--red);

    align-items: center;
    color: var(--red);
    display: flex;
    flex: 0 0 36%;
    flex-direction: column;
    font-weight: 700;
    gap: 20px;
    justify-content: center;
    margin-bottom: auto;
    margin-top: auto;
    padding: 20px;
    text-align: center;
    overflow: hidden;

    @media (min-width: 768px) and (max-width: 1120px) {
      flex: 0 0 54%;
      padding-bottom: 70px;
    }

    @media (max-width: 767px) {
      min-height: 50vh;
      flex: content;
      flex: content;
      min-height: 50vh;
      max-width: 400px;
      margin-bottom: 65px;
      margin-right: auto;
      margin-left: auto;
    }
    .contentWrapper {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 15px;
      max-height: 65vh;
      overflow-y: auto;
      padding: 10px;
      padding-bottom: 70px;

      @media (max-width: 767px) {
        max-height: none;
        padding-bottom: 0;
      }

      .title {
        font-size: 230%;
      }
      .lblCharity {
        color: #707070;
        font-size: 20px;
        font-weight: 700;
        letter-spacing: -0.46px;
        line-height: 28px;
        position: relative;
        right: 14%;
      }

      .donationList {
        max-height: 275px;
        overflow-y: scroll;
        display: flex;
        gap: 20px;
        padding-left: 10px;
        padding-top: 10px;
        flex-direction: column;
        padding-bottom: 10px;
        border-bottom: solid 1px var(--light);

        .preselectedCharity {
          display: flex;
          width: 90%;

          .itemImage {
            align-items: center;
            display: flex;
            height: 71px;
            position: relative;
            width: 115px;

            img {
              max-width: 77%;
              border-radius: 5px;
            }

            .btnRemove {
              align-items: center;
              background-color: #fff;
              border: 1px solid var(--gray);
              border-radius: 30px;
              color: var(--red);
              cursor: pointer;
              display: flex;
              font-size: 13px;
              height: 21px;
              justify-content: center;
              left: -8px;
              position: absolute;
              top: -10px;
              width: 21px;
            }
          }

          .itemDetail {
            margin-top: auto;
            margin-bottom: auto;

            @include small-desktop {
              margin-left: 8px;
            }

            .itemName {
              color: var(--red);
              font-size: 95%;
              text-align: left;

              @media (max-width: 430px) {
                font-size: 18px;
              }
            }

            .itemDesc {
              color: var(--gray);
              font-size: 85%;
              font-style: italic;
              font-weight: normal;
              letter-spacing: -0.32px;
              line-height: 15px;
              text-align: left;

              @media (max-width: 430px) {
                font-size: 14px;
              }
            }
          }

          .itemPrice {
            color: var(--gray);
            font-size: 20px;
            margin: auto;
            font-weight: normal;
            text-align: right;
            padding-right: 15px;

            @media (max-width: 430px) {
              font-size: 22px;
            }
          }
        }
      }
      .addDonationBtn {
        background-color: #fff;
        border: 2px solid var(--red);
        border-radius: 25px;
        color: var(--red);
        font-size: 130%;
        font-weight: 700;
        height: 54px;
        margin-bottom: -10px;
        width: 310px;
        overflow: hidden;
      }

      .continueBtn {
        background: #0000 linear-gradient(105deg, #f2b74e, #e89d18) 0 0 no-repeat padding-box;
        border: none;
        border-radius: 35px;
        box-shadow: 2px 3px 40px rgba(0, 0, 0, 0.133);
        color: #fff;
        font-size: 120%;
        font-weight: 700;
        height: 51px;
        width: 310px;
        opacity: 1;
      }
      .continueBtn:disabled {
        background: #e2d682;
        cursor: not-allowed;
      }
      .checkbox {
        display: flex;
        text-align: left;
        font-size: 10px;
        letter-spacing: 0.5px;
        gap: 5px;
      }
    }
  }
}
