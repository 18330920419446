@import "../../../../css/_mixins.scss";

.bgBackground {
  position: absolute;
  top: 45%;
  left: 50%;
  max-width: 90%;
  // min-height: 60%;
  max-height: 100%;
  transform: translate(-50%, -50%);
  @media (max-width: 767px) {
    display: none;
  }
}

.form {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50%;
  max-width: 600px;
  // height: 70vh;
  padding: 20px;
  margin: 0 auto;
  top: 10%;
  text-align: center;
  background-color: #fff;
  border-radius: 26px;

  @media (max-width: 767px) {
    width: 80%;
    height: 65%;
  }

  .title {
    text-align: center;
    color: var(--red);
    font-size: 28px;
    line-height: 36px;
    font-weight: 700;
    margin: 2vh 20px;

    img {
      max-width: 100%;
    }

    @media (max-width: 928px) {
      font-size: 18px;
      margin-bottom: 30px;
    }

    @media (max-width: 420px) {
      font-size: 20px;
    }
  }

  .imgCard {
    display: block;
    margin-top: 32px;
  }

  .multiInfo {
    margin-top: 40px;
    max-width: 540px;
    color: var(--gray);
    text-align: center;
    font-size: 22px;
    line-height: 32px;
    font-weight: 600;
    letter-spacing: 0px;
  }

  .inputWrapper {
    box-sizing: border-box;
    margin-top: 24px;

    @media (max-width: 928px) {
      margin-top: 12px;
    }
  }

  .redemptionDetail {
    .inputCode {
      width: 70%;
      height: 70px;
      text-align: center;
      font-size: 40px;
      color: var(--red);
      text-align: center;
      font-weight: bold;
      letter-spacing: -1px;
      border: 3px solid var(--red);
      border-radius: 30px;
      text-transform: uppercase;
      @media (max-width: 928px) {
        font-size: 30px;
        height: 55px;
      }
    }

    .linkChangeCode {
      margin-top: 5px;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0px;
      text-decoration: none !important;
      display: block;
      cursor: pointer;
    }

    .inputEmail {
      width: 70%;
      height: 70px;
      color: var(--red);
      text-align: center;
      font-weight: 300;
      border: 3px solid var(--red);
      border-radius: 30px;
      font-size: 20px;
      padding: 15px;

      @media (max-width: 928px) {
        // font-size: 30px;
        height: 55px;
      }
    }

    .lblEmail {
      margin-top: 10px;
      color: var(--red);
      text-align: center;
      font-size: 14px;
      font-style: italic;
      letter-spacing: 0px;
    }
  }

  .termsWrapper {
    display: flex;
    width: 80%;
    height: 10%;

    .lblTerms {
      display: block;
      max-width: 630px;
      margin-left: 8px;
      font-size: 14px;
      line-height: 20px;
      text-align: left;
      color: var(--gray);
      letter-spacing: 0px;

      @media (max-width: 928px) {
        font-size: 10px;
        margin-top: 20px;
        line-height: normal;
        margin-top: 10px;
      }
    }
  }

  .lblError {
    margin-top: 40px;
    color: var(--red);
    text-align: center;
    font-size: 18px;
    font-weight: bold;
  }

  .btnContinue {
    margin: 20px;

    button {
      @media (max-width: 767px) {
        font-size: 18px;
        max-width: 50%;
        margin-bottom: 40px;
      }
    }
  }
}

.container {
  position: relative;
  height: 100vh;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.8;
  }
}
