@import "../../../../css/_mixins.scss";

.preSelContainer {
  width: 100%;
}
.stepLabel {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  line-height: 18px;
  color: var(--white);
  top: 50%;
  transform: translateY(-50%);

  .totalPrice {
    position: absolute;
    right: 0%;
    bottom: 0px;
    display: flex;
    align-items: center;
    font-size: 20px;
    .tag {
      @media (max-width: 600px) {
        display: none;
      }
    }

    span {
      margin-left: 16px;
      font-size: 32px;
    }
  }
}
.chosenCharitiesTitle {
  font-size: 24px;
  font-weight: bold;
  margin: 30px 0 30px;

  @media (max-width: 928px) {
    display: flex;
    flex-wrap: wrap;
    font-size: 20px;
    margin: 20px;
    white-space: wrap;
    text-align: center;
  }
}

.chosenCharities {
  max-height: 100vh;
  margin-top: 16px;
  overflow: auto;

  @include mobile {
    height: auto;
    max-height: none;
    overflow: visible;
  }

  .chosenCharity {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 30px;

    @media (max-width: 928px) {
      flex-direction: column;
    }
    
    .charityLogo {
      justify-content: center;
      align-items: center;
      height: 110px;
      width: 180px;
      padding: 15px;
      display: flex;

      img {
        font-weight: bolder;
        border-radius: 20px;
        max-width: 100%;
        max-height: 100%;
      }

      @include tablet {
        img {
          height: 80px;
          max-width: 140px;
          margin-bottom: 15px;
        }
      }
      @include mobile {
        margin-bottom: 15px;

        img {
          height: 85px;
          object-fit: contain;
          border-radius: 50%;
          width: 85px;
          border: 2px solid var(--concrete);
        }
      }
    }

    .charityDetail {
      flex: 1;
      margin-left: 32px;
      text-align: left;
      @media (max-width: 928px) {
        text-align: center;
        margin-left: 0;
      }
      .charityName {
        font-size: 20px;
        font-weight: bold;

        @media (max-width: 928px) {
          display: block;
          text-align: center;
        }
      }

      .charityDesc {
        white-space: nowrap;
        cursor: pointer;
        color: #999;
        margin: 10px 0;

        i {
          margin-right: 8px;
          @media (max-width: 767px) {
            margin-right: 0px;
          }
        }
      }
    }

    .btnSelect {
      min-width: auto;
      height: 45px;
      margin-left: 32px;
      font-size: 16px;

      @media (max-width: 928px) {
        display: inline;
        clear: both;
      }
      @include mobile {
        margin-left: 0;
      }
    }
  }
}

.btnFullList {
  height: 55px;
  font-size: 20px;
  @media (max-width: 928px) {
    text-align: center;
    vertical-align: middle;
  }
}
.container {
  min-height: 80vh;
  text-align: center;
  width: 100%;
  width: 60%;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  @media (min-width: 1081px) {
    display: flex;
    width: 100vw;
  }

  .content {
    align-items: center;
    padding: 20px;

    @media only screen and (max-width: 1024px) {
      width: 100vw;
    }
  }
  .charityContent {
    background-color: var(--concrete);
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    padding: 64px 32px;

    @media only screen and (max-width: 1024px) {
      text-align: center;
      width: 25%;
      width: 34%;
      flex: 1;
      width: 100vw;
    }

    .lblCharity {
      font-size: 22px;
      line-height: 28px;
      font-weight: bold;
      letter-spacing: -0.46px;
      color: #707070;
    }

    .preselectedCharity {
      display: flex;
      align-items: center;
      margin-top: 40px;
      @media (max-width: 1080px) {
        padding-bottom: 40px;
        padding-left: 20%;
        padding-right: 20%;
        position: relative;
      }
      @include mobile {
        padding-left: 0;
        padding-right: 0;
      }

      .itemImage {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 70px;
        flex-shrink: 0;
        background: var(--white);
        border-radius: 6px;

        img {
          max-width: 100%;
        }

        .btnRemove {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: -15px;
          left: -15px;
          width: 30px;
          height: 30px;
          background-color: white;
          color: var(--red);
          font-size: 20px;
          border: 1px solid var(--gray);
          border-radius: 30px;
          cursor: pointer;
        }
      }

      .itemDetail {
        flex: 1;
        margin-left: 20px;
        text-align: left;

        @include small-desktop {
          margin-left: 8px;
        }

        .itemName {
          color: var(--red);
          font-size: 18px;
          line-height: 22px;
          font-weight: 700;

          @include mobile {
            font-size: 18px;
          }
        }

        .itemDesc {
          margin-top: 5px;
          color: var(--gray);
          font-size: 14px;
          font-style: italic;
          line-height: 18px;

          @include mobile {
            font-size: 14px;
          }
        }
      }

      .itemPrice {
        color: var(--gray);
        font-size: 15px;
        line-height: 30px;
        text-align: right;

        @include mobile {
          font-size: 22px;
        }
      }
    }

    .priceTotal {
      font-size: 24px;
      font-weight: bold;
      margin-top: 40px;
      color: var(--red);

      p {
        font-size: 14px;
        color: var(--gray);
        font-weight: normal;
        font-style: italic;
      }
    }

    .shareContact {
      padding: 16px 16px 16px 32px;
      margin-top: 8px;
      background-color: white;

      @media only screen and (min-width: 768px) and (max-width: 1024px) {
        width: 40%;
        margin-left: auto;
        margin-right: auto;
      }
      @media only screen and (max-width: 767px) {
        width: 90%;
      }
    }

    .btnContinue {
      width: 324px;
      height: 48px;
      margin: 16px auto 0;
      font-size: 18px;

      @include small-desktop {
        margin-top: 40px;
      }

      @include mobile {
        width: 100%;
      }
    }

    .btnStepBack {
      position: relative;
      width: 180px;
      height: 36px;
      margin: 16px auto 0;
      color: var(--text-gray);
      font-size: 14px;
      font-weight: bold;
      border: 2px solid var(--text-gray);
      background-color: var(--white);
      border-radius: 35px;

      i {
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);
        font-size: 20px;
      }
    }
  }
}
