.header {
  display: flex;
  flex-wrap: wrap;
  // width: 60vw;

  .headerRow {
    @media (min-width: 1000px) {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .dropdown-toggle {
      vertical-align: 0.05em;
      font-size: 1.8rem;
      color: var(--red);
    }
  }

  .inputSearch {
    display: flex;
    align-items: center;
    height: 60px;
    padding: 0 20px;
    border: 3px solid var(--red);
    border-radius: 25px;
    overflow: hidden;
    @media (max-width: 767px) {
      display: block;
      height: 45px;
      padding: 0 0px;
      overflow: visible;
      width: 200px;
      position: relative;
      margin-top: 20px;
      margin-bottom: 10px;
    }

    .input {
      height: 100%;
      margin-left: 8px;
      font-size: 24px;
      font-weight: bold;
      color: var(--gray);
      border: none;
      @media (max-width: 767px) {
        width: 160px;
        height: 40px;
        border-radius: 50px;
        text-align: left;
      }
    }

    i {
      font-size: 24px;
      color: var(--red);
      @media (max-width: 767px) {
        position: absolute;
        right: 10px;
        bottom: 10px;
      }
    }
  }

  .filterWrapper {
    .filterTrigger {
      font-size: 22px;
      font-weight: bold;
      text-align: right;
      color: var(--yellow);
    }

    .filterList {
      width: 100%;
      max-height: 400px;
      overflow: auto;
      @media (max-width: 767px) {
        max-height: 160px;
        overflow-y: auto;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 1;
      }

      .filterItem {
        padding: 4px 16px;
        font-size: 16px;
        color: var(--gray);
        cursor: pointer;

        &.clear {
          color: var(--red);
        }
      }
    }

    @media (max-width: 767px) {
      position: relative;
    }
  }
}

.checkMainCharities {
  margin-top: 16px;
}

.content {
  width: 100%;
  margin-top: 16px;

  .tip {
    color: var(--red);
    font-weight: bold;
    text-align: left;

    @media (max-width: 767px) {
      margin-top: 5%;
      margin-bottom: 5%;
      font-weight: bold;
      white-space: nowrap;
      vertical-align: middle;
    }
  }

  .divFilters {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;

    label {
      padding: 5px;
      vertical-align: middle;
      font-size: 0.8em;
    }

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: left;
      margin-left: 4%;
      gap: 0px;
      // white-space: nowrap;
      text-align: left;
    }
  }

  .charityList {
    height: 500px;
    margin-top: 8px;
    border: 1px solid var(--gray);
    color: var(--gray);
    border-radius: 15px;
    overflow: auto;

    @media (max-width: 1100px) {
      right: 20px;
      position: relative;
    }
    /* width */
    &::-webkit-scrollbar {
      width: 30px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background-color: var(--concrete);
      border-radius: 20px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: var(--yellow);
      border-radius: 20px;
      border: 3px solid var(--concrete);
    }

    .noResults {
      color: var(--red);
      font-weight: bold;
    }

    .charityItem {
      display: flex;
      align-items: center;
      margin: 16px 32px;
      -webkit-user-select: none;
      user-select: none;
      cursor: pointer;

      .btnView {
        font-size: 16px;
        font-weight: bold;
        white-space: nowrap;
        @media (max-width: 767px) {
          font-size: 10px;
        }

        i {
          margin-right: 8px;
          @media (max-width: 767px) {
            margin-right: 0px;
          }
        }
      }

      .charityName {
        margin-left: 40px;
        font-size: 24px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media (max-width: 767px) {
          font-size: 10px;
          margin-left: 20px;
          // white-space: wrap;
        }

        &.selected {
          color: var(--red);
          font-weight: bold;
        }
      }
   
      .iconSelected {
        margin-left: 16px;
        color: var(--red);
        font-size: 30px;
        @media (max-width: 767px) {
          font-size: 20px;
        }
      }
    }
  }
}
