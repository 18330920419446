@import "../../../../css/mixins";

.container {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  text-align: center;
  align-items: stretch;

  .selectCharity {
    padding: 100px 0;
    text-align: center;
    //max-width: 80%;
    justify-content: center;
    display: flex;
    flex: 1 1;

    @media (max-width: 920px) {
      display: block;
      padding: 10px;
    }
  }

  @media (max-width: 920px) {
    width: 100%;
    padding: 10px;
  }

  .charityChosen {
    width: 400px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 64px 32px;
    text-align: left;
    background-color: var(--concrete);

    @media (max-width: 1200px) {
      flex: 1;
      width: auto !important;
      padding: 5%;
    }
    @media (max-width: 920px) {
      display: block;
    }

    .lblCharity {
      font-size: 22px;
      line-height: 28px;
      font-weight: bold;
      letter-spacing: -0.46px;
      color: #707070;
    }

    .preselectedCharity {
      display: flex;
      align-items: center;
      margin-top: 40px;

      .itemImage {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        background: var(--white);
        border-radius: 6px;

        img {
          max-width: 100px;
        }

        .btnRemove {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: -15px;
          left: -15px;
          width: 30px;
          height: 30px;
          background-color: white;
          color: var(--red);
          font-size: 20px;
          border: 1px solid var(--gray);
          border-radius: 30px;
          cursor: pointer;
        }
      }

      .itemDetail {
        flex: 1;
        margin-left: 20px;

        @include small-desktop {
          margin-left: 8px;
        }

        .itemName {
          color: var(--red);
          font-size: 19px;
          line-height: 23px;
          font-weight: 700;
          letter-spacing: -0.38px;

          @include mobile {
            font-size: 18px;
          }
        }

        .itemDesc {
          margin-top: 5px;
          color: var(--gray);
          font-size: 16px;
          font-style: italic;
          line-height: 19px;
          letter-spacing: -0.32px;

          @include mobile {
            font-size: 14px;
          }
        }
      }

      .itemPrice {
        color: var(--gray);
        font-size: 25px;
        line-height: 30px;
        text-align: right;
        letter-spacing: -0.5px;

        @include mobile {
          font-size: 22px;
        }
      }
    }

    .priceTotal {
      font-size: 24px;
      font-weight: bold;
      margin: 25px 0 25px 0;
      color: var(--red);
      .addtoDonationBreakdown{
        font-size: 18px;        
      }

      p {
        font-size: 14px;
        color: var(--gray);
        font-weight: normal;
        font-style: italic;
      }
    }

    .shareContact {
      padding: 16px 16px 16px 32px;
      margin-top: 8px;
      background-color: white;

      .shareInfoForm{
       display: flex;
        flex-wrap: wrap;
        flex-direction: row;

        .shareInfoInput{
          border-radius: 5px;
          border: 1px solid lightgray;
          width: 45%;
          margin: 5px;
        }
      }
    }

    .btnContinue {
      height: 48px;
      margin: 16px auto 0;
      font-size: 18px;
      justify-content: center;
      display: flex;
      text-align: center;
      padding-top: 8px;

      @include small-desktop {
        margin-top: 40px;
      }

    }

    .btnStepBack {
      position: relative;
      width: 180px;
      height: 36px;
      margin: 16px auto 0;
      color: var(--text-gray);
      font-size: 14px;
      font-weight: bold;
      border: 2px solid var(--text-gray);
      background-color: var(--white);
      border-radius: 35px;
      float: right;

      i {
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);
        font-size: 20px;
      }
    }
  }
}
