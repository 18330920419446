.container {
  position: relative;
  padding : 5% 10%;

  .carouselContainer {
    position: relative;
  }

  .sliderItem {
    display: flex;
    @media screen and (max-width: 1080px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    .icon {
      width : 80px;
      height: 70px;
    }

    .itemDetail {
      margin-left: 16px;

      .caption {
        font-size  : 28px;
        font-weight: bold;
        color      : var(--yellow);
      }

      .text {
        color      : var(--gray);
        font-size  : 16px;
        line-height: 24px;
      }

      .link {
        margin-top     : 16px;
        font-size      : 18px;
        font-weight    : bold;
        text-decoration: none;
      }
    }
  }

  .btnArrow {
    position        : absolute;
    left            : 5%;
    top             : 50%;
    display         : flex;
    align-items     : center;
    transition      : all .5s;
    z-index         : 1000;
    border          : none;
    background-color: transparent;
    font-size       : 24px;
    color           : var(--gray);
    font-weight     : 700;
    letter-spacing  : -0.48px;
    transform       : translate(-50%, -50%);

    &.right {
      left : auto;
      right: 5%;
    }

    i {
      font-size: 40px;
    }
  }
}