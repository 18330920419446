@import "../../../../css/_mixins.scss";

.container {
  display: flex;
  justify-content: space-between;
  padding: 20px;

  .logo {
    width: 70%;
    max-width:380px;
    margin-right: 32px;

    img {
      display: block;
      max-width: 100%;
    }
  }

  .menuBars {
    color: var(--red);
    font-size: 45px;
    font-weight: bold;
    cursor: pointer;
  }
}
