@import "../../../../css/_mixins.scss";

.customHeader {
  height: 100px;
  padding: 10px 0;
  text-align: center;

  img {
    max-height: 100%;
    object-fit: contain;
  }
}

.container {
  .content {
    display: flex;
    justify-content: space-between;
    padding: 8px 80px;

    @include small-desktop {
      padding: 8px 64px;
    }

    @include small-desktop {
      padding: 8px 32px;
    }

    .logo {
      max-width: 388px;
      margin-right: 32px;

      img {
        display: block;
        max-width: 100%;
      }
    }

    .navLinks {
      display: flex;
      align-items: center;

      @include small-desktop {
        display: none;
      }

      .navLink {
        margin-left: 64px;
        font-size: 26px;
        font-weight: 700;
        text-decoration: none;         
        &::marker {
          display: none;
        }

        @include medium-desktop {
          font-size: 22px;
          margin-left: 32px;
        }
      }
    }

    .navbarButtons {
      display: none;
      align-items: center;
      color: var(--red);
      font-size: 25px;
      font-weight: bold;
      cursor: pointer;

      @include small-desktop {
        display: flex;
      }

      .btnUser {
        display: flex;
        align-items: center;

        @include tablet {
          display: none;
        }
      }

      .togglerLabel {
        margin: 0 24px;
      }

      i {
        font-size: 40px;
      }
    }
  }

  .navMenuContent {
    padding: 40px 64px;

    .menuLink {
      display: block;
      font-size: 20px;
      text-decoration: none;
    }

    .subLinks {
      padding-left: 24px;
    }
  }
}
