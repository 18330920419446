@import "../../../../css/_mixins.scss";

.container {
  display        : flex;
  justify-content: center;
  align-items    : center;
  flex-direction : column;
  width          : 100%;
  min-height     : 75vh;
  padding        : 16px;
  text-align     : center;

  .title {
    text-align : center;
    color      : var(--red);
    font-size  : 45px;
    font-style : normal;
    font-weight: 700;

    @include tablet {
      font-size  : 36px;
      line-height: 48px;
    }
  }

  .occasionSelector {
    width     : 462px;
    margin-top: 44px;

    @include mobile {
      width: 100%;
    }
  }

  .btnContinue {
    margin-top: 40px;
    width     : 462px;

    @include mobile {
      width: 100%;
    }
  }
}