.container {
  display: flex;
  align-items: center;
  padding: 70px 0;
  background-color: var(--red);
  text-align: center;
  @media screen and (max-width: 1080px) {
    padding: 30px 0;
  }

  .title {
    max-width: 1000px;
    margin: auto;
    font-size: 46px;
    font-weight: bold;
    color: white;
    @media screen and (max-width: 1080px) {
      font-size: 27px;
      width: 80%;
    }
  }
}
