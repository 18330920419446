.content {
  width: 100%;
  text-align: center;
  color: #fff;
  .section1 {
    background-color: var(--red);
    padding: 15px;
    img{
      width:90%;
      max-width:1000px;
      margin-bottom: -50px;;
    }
  }
  .section2 {
    background-color: var(--yellow);
    padding: 15px 15px 35px 15px;
    text-align: center;
    h1 {
      font-weight: bold;
      font-size: 30px;
      color: white;
      max-width: 90%;
      padding-top: 30px;
      margin: 0 auto;
    }

    h2 {      
      font-weight: bold;
      font-size: 16px;
      line-height: 25px;
      color: white;      
      margin: 28px auto 20px auto;
    }
  }
  .section3 {
    background-color: var(--red);
    font-weight: bold;
    font-size: 25px;
    color: #fff;
    padding: 18px 18px 40px 18px;
    h1 {
      font-weight: bold;
      font-size: 90px;
      color: #fff;
      max-width: 90%;
    }
  }
  .downArrow {
    width: 0;
    height: 0;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
    border-top: 50px solid var(--red);
    margin: 0 auto 15px auto;
  }
}
