@import "../../../../css/_mixins.scss";
.yesBtn, .noBtn {
  border: 25px;
  margin-inline: 7px;
  padding-inline: 22px;
  color: white;
  font-size: 17px;
  float: right;
  height: 50px;
  border-radius: 25px;
  font-weight: bold;
  margin: 10px 6px;
  min-width: 13%;
}
.yesBtn {
  background-color: var(--yellow);
}

.noBtn {
  background-color: var(--red);
}
.container {
  display        : flex;
  justify-content: center;
  align-items    : center;
  flex-direction : column;
  width          : 100%;
  min-height     : 75vh;
  padding        : 16px;
  text-align     : center;

  .imgTop {
    max-width: 70%;
    @media (max-width: 767px) {
      max-width: 100%;
      width: max-content;
      height: 180px;
    }
  }

  .title {
    text-align : center;
    color      : var(--red);
    font-size  : 36px;
    font-style : normal;
    font-weight: 700;

    @include mobile {
      font-size: 30px;
      margin-bottom: 100px;
    }
  }

  .actions {
    display: flex;

    @media (max-width: 767px) {
      flex-direction: column;
    }

    .btnType {
      margin-top: 40px;

      &:not(:first-child) {
        margin-left: 16px;
      }

      @media (max-width: 767px) {
        font-size: large;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px;
        white-space: wrap;
      }

      i {
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);
      }
    }
  }

  .btnContinue {
    width     : 350px;
    margin-top: 40px;

    @media (max-width: 767px) {
    height: 9%;
    width: 50%;
    }
  }
}