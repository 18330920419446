@import "../../../../css/_mixins.scss";

.customContent {
  padding: 28px 40px;
  background-color: var(--red);

  @include small-desktop {
    padding: 24px 32px;
  }

  @include mobile {
    padding: 24px 16px;
  }
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  background-color: var(--red);
  padding: 0 40px;

  @include small-desktop {
    padding: 0 32px;
  }
  @media (max-width: 700px) {
    margin-left: 0px;
    padding: 3px;
  }
  //@include mobile {
  //  padding: 0 16px;
  //}

  .inputRedeemCode {
    width: 230px;
    height: 50px;
    text-align: center;
    font-size: 18px;
    border: none;
    box-shadow: none;
    text-transform: uppercase;

    @media screen and (max-width: 1080px) {
      height: 47px;
      max-width: 45%;
      font-size: calc(50% + 1vw);
    }
  }

  .leftContent {
    display: flex;

    @include small-desktop {
      display: none;
    }

    .mainMenu {
      margin-right: 32px;
    }

    .linkLogin {
      font-size: 25px;
      font-weight: bold;
      color: white;
      text-decoration: none;
      cursor: pointer;

      i {
        margin-right: 7px;
        font-size: 30px;
      }
    }

    .linkLogin:hover {
      color: var(--light-yellow) !important;
    }

    .linkCart {
      margin-right: 20px;
      font-size: 25px;
      font-weight: bold;
      color: white;
      text-decoration: none;
      cursor: pointer;

      i {
        margin-right: 20px;
        font-size: 25px;
      }

      .cartBadge {
        color: #fff;
        background-color: var(--yellow);
        position: relative;
        left: 40px;
        top: -13px;
        border-radius: 50px;
        font-size: 12px;
        padding: 7px;
        display: inline-block;
        line-height: 1;
      }
    }

    .linkCart:hover {
      color: var(--light-yellow) !important;
    }
  }

  .rightContent {
    display: flex;
    align-items: center;

    @include small-desktop {
      justify-content: space-between;
      width: 100%;
    }

    .redeemContent {
      display: flex;
      align-items: center;

      @include mobile {
        width: 100%;
      }

      .btnRedeemContent {
        margin-left: 12px;
        text-align: center;
        color: white;

        .btnRedeem {
          border: none;
          background-color: transparent;
          color: white;
          font-size: 22px;
          font-weight: bold;
          cursor: pointer;

          @include small-desktop {
            font-size: 21px;
          }

          @include mobile {
            width: 100%;
            height: 46px;
            font-size: 14px;
            border: 2px solid var(--concrete);
            border-radius: 35px;
          }
        }

        .btnRedeem:hover {
          color: var(--light-yellow) !important;
        }

        .linkMultiRedeem {
          display: block;
          font-size: 12px;
          font-weight: 600;
          color: white;
          text-decoration: none;

          @include small-desktop {
            font-size: 10px;
            font-weight: 600;
          }

          @include mobile {
            display: none;
          }
        }

        .linkMultiRedeem:hover {
          color: var(--light-yellow) !important;
        }
      }
    }

    .btnGiveGift {
      margin-left: 60px;
      flex-shrink: 0;
      padding: 0 30px;
      height: 50px;
      font-size: 20px;
      font-weight: bold;
      line-height: 44px;
      border-radius: 30px;
      background-color: var(--yellow);
      border: 3px solid var(--yellow);
      color: var(--white);
      text-decoration: none;

      @media (max-width: 900px) {
        width: 20%;
        font-size: 63%;
        padding: 3px;
        text-align: center;
        margin-left: 0px;
      }
      //@include small-desktop {
      //  width: 235px;
      //  height: 52px;
      //  margin-left: 16px;
      //  font-size: 21px;
      //}

      @include mobile {
        display: none;
      }
    }
  }
}
