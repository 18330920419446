@import "../../../css/_mixins.scss";
.header {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;

  .caption {
    font-size: 28px;
    font-weight: bold;
    max-width: min(50vw, 400px);
    @media screen and (max-width: 800px) {
      text-align: center;
    }
  }

  .captionSmall {
    font-size: 18px;
    font-weight: bold;
    max-width: min(50vw, 400px);

    @media screen and (max-width: 800px) {
      text-align: center;
      width: 100%;
      max-width: 100vw;
    }
  }

  .logoImg {
    height: auto;
    max-height: min(20vh, 300px);
    width: auto;
    max-width: min(50vw, 400px);
  }

  .lonelyLogoImg {
    height: auto;
    max-height: 50vh;
    width: auto;
    max-width: 95vw;
  }
}
