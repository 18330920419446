@import "../../../../css/_mixins.scss";

.container {
  display: inline-block;
  width: 100%;
  height: 75vh;
  padding: 20px 16px;
  text-align: center;

  @include mobile {
    padding-top: 12%;
  }

  .title {
    text-align: center;
    color: var(--red);
    font-size: 40px;
    font-style: normal;
    font-weight: 700;

    @media (max-width: 767px) {
      font-size: 26px;
    }
  }

  .customLogo {
    display: inline-block;
    width: 190px;
    height: 190px;
    margin-top: 32px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .btnUpload {
    position: relative;
    width: 350px;
    margin-top: 36px;

    i {
      position: absolute;
      top: 50%;
      right: 30px;
      transform: translateY(-50%);
    }
    @media (max-width: 767px) {
      font-size: large;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      width: 100%;
      min-width: 100px;
      max-width: 330px;
      white-space: wrap;
    }
  }

  .uploadDetail {
    display: inline-block;
    margin-top: 24px;

    .fileLabel {
      font-size: 20px;
      font-weight: bold;
      color: var(--gray);
      letter-spacing: -0.52px;
      margin-bottom: 10px;
    }

    .fileDetail {
      display: flex;
      align-items: center;
      margin-left: 18px;
      font-size: 15px;
      color: var(--red);
      font-weight: 700;
      letter-spacing: 0px;
      color: #c44042;

      img {
        max-width: 30px;
        max-height: 30px;
        margin-right: 8px;
      }
    }
  }

  .uploadLimitation {
    margin-top: 32px;
    font-size: 23px;
    line-height: 28px;
    color: var(--gray);
    text-align: center;
    letter-spacing: -0.46px;

    @media (max-width: 767px) {
      // flex-direction: column;
      font-size: 20px;
      width: 300px;
      margin: auto;
    }
  }

  .btnContinue {
    margin-top: 40px;
    width: 350px;

    @media (max-width: 767px) {
      align-content: center;
      justify-content: center;
      height: 9%;
      width: 15%;
      width: min-content;
      font-size: medium;
      bottom: 3%;
    }
  }
}
