@import "../../../css/_mixins.scss";

.cardLoader {
  margin: initial;
}

.designSelector {
  width: 100%;
  margin-top: 40px;

  .designLabel {
    color: var(--text-gray);
    text-align: center;
    font-size: 28px;
    font-weight: 700;

    @include tablet {
      font-size: 24px;
    }
  }

  .designsContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95%;
    margin: 0px auto;

    .carouselContainer {
      width: 100%;

      .carouselTrack {
        align-items: center;

        .carouselItem {
          padding: 36px 8px;
          cursor: pointer;

          .imgCard {
            border: 2px solid #ffffff;
            border-radius: 14px;
            overflow: hidden;
            cursor: pointer;

            &.active {
              box-shadow: 3px 3px 15px var(--red);
            }

            img {
              width: 100%;
              object-fit: contain;
              pointer-events: none;
            }

            .lblAdded {
              position: absolute;
              top: 0;
              left: 50%;
              transform: translateX(-50%);
              font-size: 20px;
              font-weight: 700;
              color: var(--red);
            }
          }
        }
      }
    }

    .btnArrow {
      position: absolute;
      left: -40px;
      display: flex;
      align-items: center;
      transition: all 0.5s;
      z-index: 1000;
      border: none;
      background-color: transparent;
      font-size: 24px;
      color: var(--gray);
      font-weight: 700;
      letter-spacing: -0.48px;

      &.right {
        left: auto;
        right: -50px;
      }

      i {
        margin-right: 16px;
        font-size: 40px;
      }
    }

    .btnUpload {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 300px;
      height: 192px;
      margin: 0 auto;
      color: var(--text-gray);
      font-size: 24px;
      line-height: 24px;
      letter-spacing: -0.48px;
      font-weight: 500;
      border: 2px solid var(--gray);
      box-shadow: 0px 3px 15px #00000067;
      border-radius: 14px;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      .btnLabel {
        text-decoration: none;
      }

      i {
        margin-top: 16px;
        font-size: 38px;
      }
    }
  }
  .clickLabel{
    font-size: 12px;    
    color: var(--red);
    text-align: center;
    margin:0;
  }
}
.cardDetailModal {
  width: fit-content !important;
  max-width: 100% !important;
  max-height: 100% !important;
  height: fit-content !important;
  .modal-content {
    max-width: 100% !important;
    height: 100%;
  }
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}