.btnUpload {
  display        : flex;
  justify-content: center;
  align-items    : center;
  margin-top     : 24px;
  width          : 314px;
  height         : 50px;
  border         : 3px solid var(--yellow);
  border-radius  : 35px;
  color          : var(--yellow);
  font-size      : 20px;
  font-weight    : 700;
  text-decoration: none;
  cursor         : pointer;

  i {
    margin-left: 8px;
  }
}