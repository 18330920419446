@import "../../../../css/_mixins.scss";

.container {
  display: flex;
  width: 100%;

  .img {
    flex: 0 0 66%;
    object-fit: cover;

    @media (min-width: 768px) and (max-width: 1120px) {
      width: 46vw;
      flex: 0 0 46%;
    }
    @media (max-width: 767px) {
      display: none;
    }
  }
  .content {
    flex: 0 0 36%;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    // padding: 20px;
    // padding-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 2px;
    font-weight: bold;
    color: var(--red);

    @media (min-width: 768px) and (max-width: 1120px) {
      flex: 0 0 54%;
      padding-bottom: 70px;
    }

    @media (max-width: 767px) {
      min-height: 50vh;
      flex: content;
      flex: content;
      min-height: 50vh;
      max-width: 400px;
      margin-bottom: 65px;
      margin-right: auto;
      margin-left: auto;
    }
    .title {
      font-size: 248%;
      width: 90%;

      @media (max-width: 767px) {
        margin-bottom: 10px;
      }
    }
    .catFilterWrapper {
      border: 2px solid;
      border-radius: 22px;
      color: var(--yellow);
      font-size: 16px;
      font-weight: 700;
      padding: 8px 35px;
      max-width: 320px;
    }
    .orDivider {
      color: #888;
      font-weight: bold;
    }

    .inputSearch {
      align-items: center;
      border: 2px solid var(--red);
      border-radius: 25px;
      display: flex;
      height: 54px;
      overflow: hidden;
      margin-bottom: 30px;
      padding: 0 15px;
      max-width: 320px;

      @media (max-width: 767px) {
        display: block;
        height: 45px;
        margin-bottom: 10%;
        overflow: visible;
        padding: 0;
        position: relative;
        width: 82%;
      }

      .input {
        border: none;
        border-radius: 15px;
        color: var(--gray);
        font-size: 19px;
        font-weight: 700;
        height: 100%;
        text-align: center;

        @media (max-width: 767px) {
          width: 160px;
          height: 40px;
          border-radius: 50px;
          text-align: left;
        }
      }

      i {
        font-size: 24px;
        cursor: pointer;
        color: var(--red);
        @media (max-width: 767px) {
          position: absolute;
          right: 10px;
          bottom: 10px;
        }
      }
    }
  
    .continueBtn {
      background: transparent linear-gradient(105deg, #f2b74e 0%, #e89d18 100%) 0% 0% no-repeat padding-box;
      box-shadow: 2px 3px 40px #00000022;
      border-radius: 35px;
      opacity: 1;
      height: 45px;
      width: 65%;
      color: white;
      font-size: 120%;
      font-weight: bold;
      border: none;
    }
    .continueBtn:disabled {
      cursor: not-allowed;
      background: #e2d682;
    }
    .selectedCharityLbl {
      color: var(--yellow);
      font-size: bold;
      font-size: small;

      span{
        text-decoration: underline;
        display: block;
        color: var(--red);
        cursor: pointer;
      }
    }
  }
}

.lblCharity {
  font-size: 20px;
  line-height: 28px;
  font-weight: bold;
  margin-top: 30px;
  letter-spacing: -0.46px;
  color: #707070;
}

.preselectedCharity {
  align-items: center;
  display: flex;
  background-color: var(--lighter);
  padding: 40px 18px;
  margin-top: 10px;

  .itemImage {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 115px;
    height: 71px;
    flex-shrink: 0;
    background: var(--white);
    border-radius: 6px;

    img {
      max-width: 100%;
    }

    .btnRemove {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: -15px;
      left: -15px;
      width: 30px;
      height: 30px;
      background-color: white;
      color: var(--red);
      font-size: 20px;
      border: 1px solid var(--gray);
      border-radius: 30px;
      cursor: pointer;
    }
  }

  .itemDetail {
    flex: 1;
    margin-left: 20px;

    @include small-desktop {
      margin-left: 8px;
    }

    .itemName {
      color: var(--red);
      font-size: 19px;
      line-height: 23px;
      font-weight: 700;
      letter-spacing: -0.38px;

      @media (max-width: 430px) {
        font-size: 18px;
      }
    }

    .itemDesc {
      margin-top: 5px;
      color: var(--gray);
      font-size: 16px;
      font-style: italic;
      line-height: 19px;
      letter-spacing: -0.32px;
      @media (max-width: 430px) {
        font-size: 14px;
      }
    }
  }

  .itemPrice {
    color: var(--gray);
    font-size: 27px;
    letter-spacing: -0.5px;
    line-height: 30px;
    margin-inline: 50px;
    text-align: right;

    @media (max-width: 430px) {
      font-size: 22px;
    }
  }
}
.modalContinue {
  float: right;
  font-size: 16px;
  height: 50px;
  letter-spacing: 0;
  min-width: 210px;
  padding: 0 0;
  margin-top: 30px;
}
.modalContinue:disabled {
  cursor: not-allowed;
  background: #e2d682;
}
