.container {
  padding: 80px 0;
  background-color: white;
  @media screen and (max-width: 1080px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 25px;
  }

  .title {
    text-align: center;
    font-size: 46px;
    font-weight: bold;
    color: var(--yellow);

    span {
      color: var(--red);
    }
    @media screen and (max-width: 1080px) {
      font-size: 27px;
      width: 80%;
    }
  }

  .actions {
    display: flex;
    justify-content: center;
    margin-top: 32px;
    gap: 5px;
    flex-wrap: wrap;
    @media screen and (max-width: 1080px) {
      margin-top: 15px;
      flex-direction: column;
      align-items: center;
      flex-wrap: nowrap;
      gap: 0;
      a {
        max-width: 450px;
        margin:10px;
      }
    }
  }
}
