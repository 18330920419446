@import "../../../css/_mixins.scss";

.stepLabel {

  display: flex;
  margin-left: 40vw;
  margin-right: 10px;
  justify-content: space-between;
  align-items: center;
  font-size: 35px;
  line-height: 32px;
  color: var(--white);
  letter-spacing: -0.78px;
  white-space: nowrap;

  @media (max-width: 700px) {
    font-size: 30px;
    letter-spacing: -1px;
  }

  .checkoutLabel{
    margin-inline: 10px;
    font-size: 16px;
    align-self: flex-end;
    position: relative;
    margin-bottom: -5px;
  }

  @media (max-width: 1000px) {
    position: relative;
    right: 50%;
    justify-content: space-around;
    width: 100vw;
  }

  .btnMultiRedeem {
    font-size: 55%;
    cursor: pointer;

  }
}

.btnContinue {
  width: 20%;
  margin-top: 16px;

  @include tablet {
    margin-top: 44px;

    @include mobile {
      width: 100%;
    }
  }
}