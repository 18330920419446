.chanukah{
  color:var(--red);
}
.singleItem {
  margin-bottom: 6vh;
  max-width: max(27vw, 300px);
    @media screen and (max-width: 950px) {
      max-width: 95vw;
    }
  .description {
    display: flex;
  }
  a {
    text-decoration: none;
  }
  .description .icon {
    width: 80px;
    height: 70px;
  }
  .description .itemDetail {
    margin-left: 16px;
  }
  .description .itemDetail .caption {
    font-size: 25px;
    font-weight: bold;
    color: var(--yellow);
  }
  .description .itemDetail .text {
    color: #6c757d;
    font-size: 16px;
    line-height: 24px;
  }
  .link {
    margin-top: 16px;
    text-align: center;
  }
  .link a {
    font-size: 18px;
    font-weight: bold;
    text-decoration: none;
    color: var(--red);
  }
}
.carouselContainer {
  margin-top: 60px;
  background-color: var(--yellow);
  color:#fff;
  @media screen and (max-width: 1080px) {
    margin-top: 30px;
  }

  .itemWrapper {
    height: 60px;
    padding: 0 40px;        

    .clientLogo {
      max-width: 100%;
      max-height: 100%;
    }
    @media screen and (max-width: 1080px) {
      height: 50px;
      padding: 0 20px;
    }
  }
}
