.container {
  position: relative;
  width: 100%;
  background-color: var(--yellow);

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 25px 30px 0 30px;
    border-color: white transparent transparent transparent;
  }

  .content {
    padding: 70px;
    text-align: center;
    @media screen and (max-width: 1080px) {
      padding: 50px 0 30px 0;
    }

    .quoteSymbol {
      font-size: 60px;
      line-height: 40px;
      font-weight: bold;
      color: var(--red);
    }

    .title {
      font-size: 28px;
      font-weight: bold;
      color: white;
    }
    .quotesContainer {
      .quoteWrapper {
        width: 100%;
        text-align: center;
        .quote {
          font-size: 28px;
          color: white;
        }

        .quoter {
          margin-top: 16px;
          font-size: 20px;
          font-style: italic;
          font-weight: 600;
          color: var(--red);
          text-align: center;
          width: 100%;
          img {
            display: inline;
            min-width: 150px;
          }          
        }
      }
    }

    .carouselContainer {
      margin-top: 60px;
      @media screen and (max-width: 1080px) {
        margin-top: 30px;
      }

      .itemWrapper {
        height: 60px;
        padding: 0 40px;        

        .clientLogo {
          max-width: 100%;
          max-height: 100%;
        }
        @media screen and (max-width: 1080px) {
          height: 50px;
          padding: 0 20px;
        }
      }
    }
  }
}
