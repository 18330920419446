@import "../../../css/mixins";

.container {
  background-image: url("/images/bg-banner-2.jpg");
  background-size: cover;
  margin:0;
  padding: 0;

  .content {
    background-color: #ffffff55;
    text-align: center;
    padding: 7px 0;

    h3 {
      color: var(--red);
      font-weight: bold;
      font-size: 1em;
      margin:0;
      padding: 0;
    }
  }
}
