@import "../../../css/_mixins.scss";

.container {
  .redBarWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .stepLabel {
      font-size: 35px;
      line-height: 32px;
      color: var(--white);
      letter-spacing: -0.78px;
      white-space: nowrap;
      margin: 0 auto;
      display: flex;

      .checkoutLabel {
        margin-inline: 10px;
        font-size: 16px;
        align-self: flex-end;
        position: relative;
        margin-bottom: -5px;
        letter-spacing: 0.5px;

        @media (max-width: 430px) {
          display: none;
        }
      }

      @media (max-width: 600px) {
        margin-left: 0;
      }

      @include tablet {
        text-align: center;
      }
    }
    .price {
      color: white;
      font-size: x-large;
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }

  .content {
    // padding-bottom: 3vh;

    .divBackButton {
      display: flex;
      justify-content: flex-end;
      padding-right: 4vw;
      align-items: center;
      max-width: 100%;

      @media (min-width: 768px) and (max-width: 1024px) {
        justify-content: right;
        margin: 6px;
        width: 50%;
        padding: initial;
        // float: right;
        position: absolute;
        // bottom: 37%;
        right: 2vw;
      }
      @media (max-width: 430px) {
        justify-content: right;
        margin: 50px 10px 10px 0;
        position: relative;
        padding: initial;
      }
      .btnStepBack {
        padding: 5px 30px;
        margin-top: -70px;
        color: var(--text-gray);
        font-size: 17px;
        font-weight: bold;
        border: 3px solid var(--text-gray);
        background-color: var(--white);
        border-radius: 35px;

        @media (max-width: 430px) {
          margin: 0px 7px;
        }

        i {
          font-size: 20px;
          display: inline-block;
          margin-left: 15px;
        }
      }
      // @media screen and (max-width: 1080px) {
      //   padding: initial;
      //   justify-content: center;
      //   margin-top: 50px;
      //   .btnStepBack {
      //     margin-top: 0;
      //   }
      // }
    }
  }
}
