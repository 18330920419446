@import "../../../css/_mixins.scss";

.container {
  position     : relative;
  height       : 60px;
  border       : 3px solid var(--red);
  border-radius: 35px;

  &.selected {
    border-color: var(--yellow);

    .btnToggle {
      color: var(--yellow);
    }
  }

  .btnToggle {
    width          : 100%;
    padding        : 0 40px;
    line-height    : 55px;
    color          : var(--red);
    text-decoration: none;
    font-weight    : bold;
    font-size      : 26px;
    text-overflow  : ellipsis;
    overflow       : hidden;
    white-space    : nowrap;
  
    @include mobile {
      font-size: 24px;
    }
  
    &::after {
      position : absolute;
      top      : 50%;
      right    : 16px;
      transform: translateY(-50%);
    }
  }
  
  .content {
    width     : 100%;
    max-height: 500px;
    overflow  : auto;
    left      : 0;
    inset     : 0;
    transform : none;
  }
}

.btnCustomImage {
  margin-top: 16px;
  width     : 462px;

  @include mobile {
    width: 100%;
  }
}
