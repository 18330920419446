.container {
  display: flex;
  width: 100%;
  padding: 40px 80px 80px;
  @media screen and (max-width: 1080px) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0px;
  }

  .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    padding: 0 80px 0 100px;
    @media screen and (max-width: 1080px) {
      width: 75%;
      padding: 15px;
    }

    .title {
      font-size: 30px;
      font-weight: bold;
      color: var(--red);
      @media screen and (max-width: 1080px) {
        font-size: 22px;
      }
    }

    .subTitle {
      margin-top: 16px;
      font-size: 20px;
      line-height: 24px;
      font-weight: bold;
      color: var(--gray);
      @media screen and (max-width: 1080px) {
        font-size: 22px;
        line-height: 30px;
      }
    }

    .description {
      margin-top: 24px;
      color: var(--gray);
      @media screen and (max-width: 1080px) {
        font-size: 22px;
        line-height: 30px;
      }
    }
  }

  .right {
    position: relative;
    width: 50%;
    @media screen and (max-width: 1080px) {
      width: 75%;      
    }

    .iconDirection {
      position: absolute;
      left: 0;
      top: 15%;
      width: 150px;
      transform: translateX(-50%);
      @media screen and (max-width: 1080px) {
        width: 100px;
      }
    }

    .bgRight {
      max-width: 100%;
    }
  }
}
