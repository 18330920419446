/* Add your custom styles below */
.font-smaller {
  font-size: larger;
}

.very-small {
  font-size: 0.5em;
}

.display-5 {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.3;
}

/* Utilities */

.vertical-align {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

/* End Utilities */

a.navbar-brand img {
  max-width: 100%;
}

.badge-light {
  background-color: #fafafa;
  color: var(--yellow);
}

.badge-primary {
  color: #fafafa;
}

/*
.btn-primary {
  color: #fafafa !important;
  background: #c44042 !important;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle,
.btn-primary:hover {
  color: #fafafa !important;
  background-color: #cf8400 !important;
  background: #cf8400 !important;
  border-color: #cf8400 !important;
}

.btn-outline-primary:hover,
.btn-outline-primary:active,
.btn-outline-primary:not(:disabled):not(.disabled).active {
  color: #fafafa !important;
}

.btn-secondary {
  color: #fafafa !important;
  background: #e89d18 !important;
}

button.btn.btn-block.btn-outline-secondary {
  color: #c44042;
}

.btn-outline-secondary:hover,
.btn-outline-secondary:active,
.btn-outline-secondary:not(:disabled):not(.disabled).active {
  color: #fafafa !important;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle,
.btn-secondary:hover {
  color: #fafafa !important;
  background-color: #de5a5c !important;
  background: #de5a5c !important;
  border-color: #de5a5c !important;
}

.btn-tertiary {
  color: #fafafa !important;
  background: #343a40 !important;
  border-color: #343a40 !important;
}

.btn-tertiary:not(:disabled):not(.disabled):active,
.btn-tertiary:not(:disabled):not(.disabled).active,
.show > .btn-tertiary.dropdown-toggle,
.btn-tertiary:hover {
  color: #fafafa !important;
  background: #1b2127 !important;
  background-color: #1b2127 !important;
  border-color: #1b2127;
}

.btn-elegant-gray {
  color: #fafafa !important;
  background: rgb(115, 114, 100) !important;
  border-color: rgb(115, 114, 100) !important;
}

.btn-elegant-gray:not(:disabled):not(.disabled):active,
.btn-elegant-gray:not(:disabled):not(.disabled).active,
.show > .btn-elegant-gray.dropdown-toggle,
.btn-elegant-gray:hover {
  color: #fafafa !important;
  background: rgb(115, 114, 100) !important;
  background-color: rgb(115, 114, 100) !important;
  border-color: rgb(115, 114, 100);
}
*/

.alert-primary {
  color: #fafafa;
  background: #e89d18;
}

.alert-secondary {
  color: #fafafa;
  background: #c44042;
}

.alert-success {
  color: #fafafa;
  background: #1cab5c;
}

.bootstrap-select .dropdown-toggle:focus,
*:focus {
  outline: 0px !important;
  outline-style: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.navbar-expand-lg .navbar-nav .dropdown-item .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-item .nav-link {
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 0;
}

.dropdown-item {
  cursor: pointer;
}

.navbar-dark .navbar-nav .dropdown-item .nav-link {
  color: #343a40;
}

.navbar-nav .dropdown-item .nav-link {
  font-weight: 400;
}

.dropdown-item .nav-link > svg {
  margin-right: 1em;
}

a.dropdown-toggle.nav-link a {
  color: #fafafa;
}

.dropdown-toggle::after {
  vertical-align: 0.05em;
  font-size: 1.8rem;
}

/* Misc. */

.underline-title {
  border-bottom: 0.15rem solid #343a40;
}

.white-background {
  background: #fafafa !important;
}

.card-background {
  background: #ededed !important;
}

.tab-content .card-background {
  background: #fafafa !important;
}

.top-radius {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.bottom-left-radius {
  border-bottom-left-radius: 5px;
}

.bottom-right-radius {
  border-bottom-right-radius: 5px;
}

.panel {
  border: none;
  border-radius: inherit;
}

.tab-content .form-panel {
  background-color: white;
  margin: 15px;
}

.form-panel {
  background-color: #ededed;
  padding: 15px;
  border-radius: 0.375rem;
}

.cc-half-left,
.cc-half-right {
  max-width: calc(50% - 0.5rem);
}

.cc-half-left {
  margin-right: 0.5rem;
}

.cc-half-right {
  margin-left: 0.5rem;
}

.cc-panel-height {
  min-height: 32.8rem;
}

.input-group:focus-within .form-control,
.input-group:focus-within .input-group-text,
.form-control:focus {
  border-color: #cf8400;
}

.w-75-rm {
  width: calc(75% - 1rem) !important;
  margin-right: 1rem !important;
}

.mt-310 {
  margin-top: 310px;
}

.small-line-height {
  line-height: 1.3em;
}

/* React Select */

.float-label-select {
  position: relative;
  top: -5.8rem;
  font-size: 60%;
  left: 12px;
  line-height: 1;
  z-index: 3;
  padding: 0 1px;
}

.react-select__control {
  border-radius: 0.375rem !important;
  border: 0.0625rem solid #ced4da !important;
  background-color: #fff !important;
  font-size: 1.25rem !important;
  line-height: 1.6 !important;
  padding: 0.075rem 0 !important;
}

.select-saved-charities {
  z-index: 6 !important;
}

.my-custom-select,
#personalInformationState,
#deliveryMethodSelect,
#cardAmountSelect,
#occasionSelect,
#layoutSelect,
#billingState,
#cardType,
#expirationMonth,
#expirationYear,
#contactInformationState,
#merchantSelect {
  z-index: 5;
}

#cardAmountSelect,
#tipAmountSelect {
  z-index: 4;
}

.react-select__control:focus-within {
  background-color: #f3f4f5 !important;
}

.react-select__control:hover,
.react-select__control--is-focused {
  box-shadow: none !important;
}

.react-select__control--is-focused {
  border-color: #cf8400 !important;
}

.invalid .react-select__control {
  border-color: #c44042 !important;
}

.react-select__single-value {
  color: #495057 !important;
}

.react-select-value {
  color: #ad8a44 !important;
}

.react-select__option--is-focused {
  background-color: #f3f4f5 !important;
}

.react-select__option--is-selected {
  background-color: #e89d18 !important;
}

.react-select__multi-value__remove:hover {
  background-color: #c44042 !important;
  color: #fff !important;
}

.form-group .input-group-text {
  padding: 5px;
  line-height: 1rem;
  height: 48.4px;
}

#tipAmountSelect,
#cardAmountSelect {
  min-width: 120px;
}

#tipAmountSelect .react-select__control,
#cardAmountSelect .react-select__control {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-left: none !important;
}

/* End React Select */

/* End Misc. */

/* Footer */

.redcross {
  background: var(--red);
  padding: 16px 0;
  text-align: center;
  font-size: 28px;
  font-style: italic;
  color: white;
}

.footer .list-group-item:first-child {
  border-top: none;
}

.footer .list-group-item,
.footer .blab {
  padding: 0.125rem 0;
  border-bottom: none;
  color: #adb5bd !important;
  font-size: 1rem;
  margin-bottom: 0;
  background-color: inherit;
}

.footer .list-group-item:focus,
.list-group-item:hover,
.footer .svg-inline--fa:hover {
  color: #e89d18 !important;
}

.footer .nav.ml-lg-auto.list-group {
  flex-direction: row;
}

.footer .nav-item.nav-link.list-group-item {
  padding: 0.25rem 0.75rem;
}

.footer .svg-inline--fa {
  color: #fafafa;
}

.footer .nav-item.nav-link.list-group-item {
  cursor: pointer;
}

.footer .list-unstyled.list-group {
  margin-bottom: 1rem;
}

.footer .blab {
  text-align: center;
}

.footer .blab a {
  color: #adb5bd !important;
}

/* End Footer */

/* Landing Page  */

.envelope-icon-front {
  width: 80vw;
  max-width: 250px;
}

.landing-page-icons {
  height: 50vh;
  max-height: 150px;
}

.justify-content-center button {
  margin: 0 auto;
}

.section-title-row {
  text-align: center;
  background-color: var(--red);
  margin-bottom: 3rem;
  box-shadow: 0 0.7rem 0.7rem rgba(0, 0, 0, 0.1) !important;
}

.section-title-row h2 {
  color: #fafafa;
  font-weight: bold;
  margin: 1rem 0;
  font-size: 28px;
  font-weight: 600;
}

.landing-corporate-logo-container {
  margin: 100px auto 0;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 0 10vw 1vh;
}

@media screen and (max-width: 760px) {
  .landing-corporate-logo-container {
    padding: 0 2vw 1vh;
  }
}

@media screen and (max-width: 420px) {
  .landing-corporate-logo-container {
    margin: 300px auto 0;
  }
}

.landing-page-corporate-logos {
  max-height: 40px;
  margin: 2vw 0;
  height: 9vw;
}

@media screen and (max-width: 600px) {
  .landing-page-corporate-logos {
    height: 6vw;
    margin: 1vw 0;
  }
  .landing-corporate-logo-container {
    margin: 62vw auto 0;
  }
  .landing-corporate-logo-container .col-auto {
    padding-right: 2vw;
    padding-left: 2vw;
  }
  .landing-corporate-logo-container .mt-4.mb-2.text-center.col-12 {
    margin-top: 2vw !important;
  }
}

section.landing-hero {
  background-image: url(../../images/hero-background.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.landing-hero .row {
  background: rgba(0, 0, 0, 0.4);
  min-height: 90vh;
  height: 100%;
}

.landing-hero h1,
.landing-hero h3,
.landing-hero h5,
.landing-corporate-logo-container h2 {
  color: #fafafa;
}

.corporate {
  background-image: url(../../images/corporate-background.jpg);
  background-repeat: no-repeat;
  background-size: initial;
  background-position: bottom;
}

@media screen and (max-width: 600px) {
  .corporate {
    background-image: url(../../images/corporate-background-sm.jpg);
    background-position: bottom;
  }
}

.container-fluid.charities {
  overflow: hidden;
}

.charity-padding-fix-bc-carousel {
  padding-left: 30px;
  padding-right: 30px;
}

/*End Landing Page*/

/* Tabs */

.nav-pills .active.nav-link {
  border-right: 0 !important;
  border-left: 0 !important;
  border-bottom: 0 !important;
  border-top-left-radius: 0.375rem !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 0.375rem !important;
  border-top: 0.375rem solid #e89d18 !important;
  color: #343a40 !important;
  background: #ededed !important;
}

.nav-pills .active.nav-link:hover {
  color: #343a40 !important;
}

.nav-pills .nav-link {
  border-right: 0 !important;
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0 !important;
  border-left: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-bottom: 0 !important;
  border-top: 0.375rem solid #fafafa !important;
  color: #343a40 !important;
  cursor: default;
}

.nav-pills .nav-link:hover {
  color: #e89d18 !important;
}

.nav-tabs {
  border-bottom: 0;
}

.nav-pills.flex-column {
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

@media screen and (max-width: 500px) {
  .physical-tab-title-first {
    padding: 1rem 10.5vw !important;
  }
}

.tab-content {
  width: calc(100% - 30px);
}

.tab-content > .active > .row {
  background: #ededed;
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.tab-content .row.tab-row-first {
  border-top-right-radius: 0.375rem;
}

.tab-content .row.tab-row-last {
  border-top-left-radius: 0.375rem;
}

.tab-content .row.tab-row-center {
  border-top-right-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
}

/* Nested Tabs */

.nav-justified.tab-nested .nav-item {
  max-width: 50%;
}

.tab-nested .nav-link,
.tab-nested .active.nav-link {
  border: none !important;
}

.tab-nested .tab-content .row {
  background: #fafafa;
  border-radius: 0.375rem;
  margin-left: 0px;
}

.tab-nested .nav-pills .nav-link .btn.btn-primary {
  color: #343a40 !important;
  background: transparent !important;
  border-color: transparent;
}

.tab-nested .nav-pills .active.nav-link .btn.btn-primary {
  color: #fafafa !important;
  background: #e89d18 !important;
}

.tab-nested .nav-pills .active.nav-link .btn.btn-primary:hover,
.tab-nested .nav-pills .active.nav-link .btn.btn-primary:focus,
.tab-nested .nav-pills .active.nav-link .btn.btn-primary:active,
.tab-nested .nav-pills .nav-link .btn.btn-primary:hover,
.tab-nested .nav-pills .nav-link .btn.btn-primary:focus,
.tab-nested .nav-pills .nav-link .btn.btn-primary:active,
.no-hover:hover,
.no-hover:focus,
.no-hover:active {
  color: #fafafa !important;
  background: #e89d18 !important;
  border-color: #e89d18 !important;
}

.no-hover {
  cursor: default !important;
}

/* End Nested Tabs */

/* End Tabs */

/* Modals */

.close > span:not(.sr-only) {
  background-color: #c44042;
  line-height: 1.3rem;
  height: 1.3rem;
  width: 1.3rem;
  border-radius: 50%;
  font-size: 1.3rem;
}

.close:focus span:not(.sr-only),
.close:hover span:not(.sr-only) {
  background-color: #de5a5c;
}

.close {
  color: #fafafa;
  opacity: 1;
}

.modal-header .close {
  padding: 1.3rem;
  margin: -1.3rem -1.3rem -1.3rem auto;
}

/* Text Modal */

.text-modal p {
  color: #e89d18;
  cursor: pointer;
}

.text-modal p:hover,
.text-modal p:focus,
.text-modal p:active {
  color: #cf8400;
}

/* End Text Modal */

/* End Modals */

/* Purchase */

.purchase-step-title {
  margin-left: 1rem;
  width: calc(100% - 4rem);
}

.physical-card-images {
  max-width: 220px;
}

.physical-card-description {
  font-size: 0.9rem;
}

.panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.panel-default {
  border-color: #ddd;
}

.panel-default > .panel-heading {
  color: #333;
  background-color: #f5f5f5;
  border-color: #ddd;
}

.panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.panel-body {
  padding: 15px;
  background-color: #ededed;
}

.panel .row {
  background: none;
}

.bottom-column {
  position: relative;
}

.bottom-column-hgsdfg {
  position: absolute;
  bottom: 12px;
}

/* Cart */

.cart .panel,
.cart .panel-body {
  background-color: #ededed;
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.cart .panel {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

.cart-btn {
  width: 10rem;
  margin: 0.375rem;
}

.cart-badge {
  position: relative;
  right: 5px;
  top: -13px;
}

.border-5 {
  border-width: 5px !important;
}

.merchant-modal-content {
  height: 600px;
}

.merchant-modal-content .modal-body {
  height: 100%;
  overflow: scroll;
}

.modal-backdrop {
  background-color: rgba(52, 58, 64, 0.5);
  backdrop-filter: none;
}

.modal-backdrop.show {
  opacity: 1;
  backdrop-filter: blur(10px);
}

.btn-login {
  cursor: pointer;
}

.btn-login:hover {
  background: rgba(255, 255, 255, 0.2);
}

.btn-login a {
  color: white !important;
  font-size: 16px;
}

.btn-checkout {
  color: white !important;
  font-size: 16px;
}

.btn-checkout:hover {
  background: rgba(255, 255, 255, 0.2);
}

.account-card {
  cursor: pointer;
}

.account-option-text {
  font-size: 15px;
  color: #e89d18;
}

.btn-link {
  cursor: pointer;
}

.option-icon {
  height: 50px;
  background-size: auto;
  object-fit: scale-down;
}

.seperator-container {
  align-items: center;
}

.seperator {
  height: 1px;
  background-color: #343a41;
  width: 100%;
}

.step1,
.step2,
.step3,
.step4,
.stepOrders {
  width: 100%;
  height: 1px;
}

/* Make card preview modal responsive-ish */

.__eCardContainer {
  margin: 0 !important;
  transform: scale(0.77);
  -ms-transform: scale(0.77);
  -webkit-transform: scale(0.77);
  -moz-transform: scale(0.77);
  -o-transform: scale(0.77);
  transform-origin: top left;
  display: block;
}

.__viewAndPrintCard .__eCardContainer {
  transform: scale(1);
  width: 100% !important;
  height: 100% !important;
  margin: 0.25in 0.25in 0 0.25in;
}

.__viewAndPrintCard .__eCardContainer > table {
  width: 100% !important;
  height: 100% !important;
}

/* .__previewCardContainer .__eCardContainer {
  transform: scale(1);
  width: 100% !important;
  height: 100% !important;
  margin: 0.25in 0.25in 0 0.25in !important;
} */

.__customSection font {
  font-size: 16px !important;
}

.modal-content {
  max-width: 100%;
}

.cardpreview .modal-content {
  max-height: 700px;
}

.login-modal .modal-content {
  max-width: initial;
}

.termsModal {
  max-width: 800px;
}

.termsModal .modal-content {
  max-width: 100% !important;
}

.redeemModal {
  max-width: 600px;
  height: 93%;
}

.redeemModal .modal-content {
  max-width: 100% !important;
  height: 100%;
}

.update-shipping-modal {
  max-width: 1000px;
}

.update-shipping-modal .modal-content {
  max-width: 100% !important;
}

.custom-radio .custom-control-label {
  display: block;
  background-color: #fff;
  margin: 5px 0 10px 0;
  border-radius: 6px;
  padding: 0 10px 10px 10px;
}

.shipping-type {
  font-size: 1.2em;
}

.category-modal {
  max-width: 800px;
}

.category-modal .modal-content {
  max-width: initial;
}

.side-menu-modal {
  margin: 0;
}

.side-menu-modal .modal-header {
  background-color: #e89d18;
  box-shadow: 0 0.7rem 0.7rem rgba(0, 0, 0, 0.1) !important;
  margin: 0;
  border-radius: 0;
}

.side-menu-modal .modal-header h3 {
  color: #fafafa;
}

.side-menu-modal .modal-content {
  width: 100vw;
  height: 100vh;
  border-radius: 0;
}

.side-menu-modal .modal-body {
  overflow: auto;
}

.side-menu-modal .modal-body > p {
  color: #de5a5c;
  font-size: 0.7em;
}

.side-menu-modal .modal-body .nav-link {
  font-size: 0.8em;
}

.side-menu-modal .modal-body .nav-link:hover {
  color: #e89d18;
  cursor: pointer;
}

.side-menu-modal .modal-body .nav-link sub {
  font-size: 0.6em;
  color: #777;
  display: block;
  line-height: 1.2em;
}
.landing-page-modal {
  width: 800px;
  max-width: 100% !important;
}
.landing-page-modal .modal-content {
  width: 800px;
  max-width: 100% !important;
}

@media screen and (max-width: 760px) {
  .__eCardContainer {
    margin: 0 !important;
    transform: scale(0.76);
    -ms-transform: scale(0.76);
    -webkit-transform: scale(0.76);
    -moz-transform: scale(0.76);
    -o-transform: scale(0.76);
    transform-origin: top left;
    display: block;
  }
  .cardpreview .modal-content {
    max-height: 680px;
  }
}

@media screen and (max-width: 500px) {
  .__eCardContainer {
    margin: 0 !important;
    transform: scale(0.66);
    -ms-transform: scale(0.66);
    -webkit-transform: scale(0.66);
    -moz-transform: scale(0.66);
    -o-transform: scale(0.6);
    transform-origin: top left;
    display: block;
  }
  .cardpreview .modal-content {
    max-height: 560px;
    max-width: 94vw;
  }
}

@media screen and (max-width: 400px) {
  .__eCardContainer {
    margin: 0 !important;
    transform: scale(0.56);
    -ms-transform: scale(0.56);
    -webkit-transform: scale(0.56);
    -moz-transform: scale(0.56);
    -o-transform: scale(0.56);
    transform-origin: top left;
    display: block;
  }
  .cardpreview .modal-content {
    max-height: 460px;
  }
  .card-body.mobile-padding {
    padding: 5px;
  }
}

.invalid-addon {
  border: 1px solid #c44042 !important;
}

.scroll-container {
  overflow: auto;
  max-height: 600px;
}

.charity-card {
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(31, 31, 31, 0.12);
}

.charity-card.selected {
  overflow: hidden;
  box-shadow: 0px 0px 12px 3px #e89d18;
}

.charity-card img {
  width: 100%;
  height: 200px;
  object-fit: contain;
  background-color: #e5e5e5;
  border-radius: 0;
}

.charity-card .img-name {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 200px;
  font-weight: bolder;
  background: #dcdcdc;
}

.charity-card .short-desc {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: #787878;
  font-size: 15px;
  line-height: 1.2;
  overflow: hidden;
}

.charity-content {
  padding: 20px 0;
  display: flex;
  align-items: flex-start;
}

.charity-filter {
  min-width: 15rem;
}

@media screen and (min-width: 1024px) {
  .charity-filter {
    min-width: 22rem;
  }
}

.charities-content {
  padding: 0 10px;
  flex: auto;
}

.charity-card-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}

.charity-chooser-input:hover {
  color: #e89d18;
  border-color: #e89d18;
}

.charity-chooser-modal {
  max-width: 1200px;
  height: 95%;
  margin: 1rem auto;
}

.charity-chooser-modal .modal-content {
  max-width: 100% !important;
  height: 100%;
  overflow: hidden;
}

.charity-chooser-modal .modal-body #charities-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  margin-top: 5rem;
  padding: 3rem;
}

.charity-chooser-modal .modal-body .charity-filter {
  flex: 1;
  padding: 1rem;
  width: 15rem;
  background-color: white;
}

.filter-drawer {
  flex-direction: column;
  position: absolute !important;
  justify-content: flex-start !important;
  align-items: initial !important;
}

.row-eq-height {
  flex-wrap: wrap;
}

.redeem-code {
  text-transform: uppercase;
}

.redeem-code::placeholder {
  text-transform: none;
}

.btn-remove-charity {
  top: -7px;
}

.btn-remove-charity > span {
  font-size: 1rem !important;
  width: 1rem !important;
  height: 1rem !important;
  line-height: 1rem !important;
}

.col-centered {
  float: none;
  margin: 0 auto;
}

.purchasePhysicalCardLink {
  padding: 7px;
  background-color: #fff;
  border: 1px solid #888;
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05);
}

.purchasePhysicalCard {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05);
}

.cardMenuItem {
  min-width: 10rem;
  cursor: pointer;
  text-align: center;
}

.addressLine {
  font-size: 0.8em;
}

.addressLine > div {
  width: 130px;
  max-width: 50%;
  display: inline-block;
  font-weight: bold;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__header {
  background-color: #c44042 !important;
}

.react-datepicker-time__header {
  color: white !important;
}

.react-datepicker__current-month {
  color: white !important;
  font-size: 24px !important;
}

.react-datepicker__day-name {
  color: white !important;
}
