@import "../../../css/_mixins.scss";

.modalTitle {
  font-weight: bold;
}

.btn {
  font-weight: bold;
  width: 100px;
  font-size: 16px;
  border-radius: 30px;
  padding-inline: 7px;
  height: 55px;
  float: right;
  margin-inline: 20px;
}

.modalYesBtn {
  width: auto + 10px;
  border: 3px solid var(--yellow);
  color: var(--yellow);
  padding-inline: 5px;
}

.modalNoBtn {
  padding-inline: 5px;
  font-size: 16px;
  border: 3px solid var(--yellow);
  color: var(--white);
  background-color: var(--yellow);
}

.container {
  display: flex;
  width: 100%;
  min-height: 80vh;
  text-align: center;

  @include small-desktop {
    flex-direction: column-reverse;
  }

  .content {
    position: relative;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 100px;

    @include small-desktop {
      padding: 100px;
    }

    @include mobile {
      padding: 80px 16px;
    }

    .cartDetail {
      display: flex;
      align-items: center;
      font-size: 39px;
      letter-spacing: -0.78px;
      color: var(--red);

      i {
        margin-right: 10px;
        font-size: 50px;
        color: var(--red);
      }
    }

    .description {
      margin: 20px 0 10px;
      font-size: 39px;
      line-height: 48px;
      color: var(--gray);
      text-align: center;
      font-weight: 500;
      letter-spacing: -0.78px;
    }

    .btnLogin {
      margin-top: 20px;
      width: 446px;

      @include mobile {
        width: 100%;
      }
    }

    .checkoutButtons {
      margin-top: 40px;
      width: 446px;

      @include mobile {
        width: 100%;
      }
    }

    .btnCorporateCheck {
      width: 446px;
      height: 45px;
      font-size: 18px;

      @include mobile {
        width: 100%;
      }
    }
  }

  .successModal {
    margin-left: -37px;
  }
}