@import "../../../../css/_mixins.scss";

.yesBtn,
.noBtn {
  border: 25px;
  margin-inline: 7px;
  padding-inline: 22px;
  color: white;
  font-size: 17px;
  float: right;
  height: 50px;
  border-radius: 25px;
  font-weight: bold;
  margin: 10px 6px;
  min-width: 13%;
}
.yesBtn {
  background-color: var(--yellow);
}

.noBtn {
  background-color: var(--red);
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 75vh;
  padding: 0 16px;
  text-align: center;

  // @media (min-width: 768px) and (max-width: 1024px) {
  //   align-items: center;
  //   display: grid;
  //   margin: 20px auto;
  //   gap: 10px;
  //   margin-top: 75px;
  // }

  @media (max-width: 767px) {
    margin: 20px auto;
    flex-wrap: wrap;
    height: auto;
    flex-direction: row;
  }
  .title {
    text-align: center;
    color: var(--red);
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    @media (max-width: 989px) {
      font-size: 30px;
      margin-bottom: 100px;
    }
  }

  .space {
    width: 35px;
  }

  .typeSelector {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 44px;
    flex-direction: row;

    @include mobile {
      display: flex;
      flex-direction: column;
    }
    @media (max-width: 989px) {
      flex-direction: column;

      button {
        flex: 1 1 auto;
        font-size: 2rem;
        width: 450px;
        margin-top: 20px;

        @media (max-width: 989px) {
          font-size: large;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          min-width: 100px;
          max-width: 330px;
          white-space: wrap;
        }
      }
    }
    .btn {
      @include mobile {
        margin: 11px;
        width: 80vw;
        font-size: 100%;
        letter-spacing: -0.75px;
        text-wrap: auto;
      }
    }
  }

  .divRemoveExisting {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    text-align: center;
    padding-bottom: 25px;

    .previewButton {
      display: block;      
      color: var(--red);
      font-size: 17px;
      font-weight: bold;
      text-decoration: none;
    }

    .removeButton {      
      padding: 5px 10px;
      color: var(--text-gray);
      font-size: 17px;
      font-weight: bold;
      border: 3px solid var(--text-gray);
      background-color: var(--white);
      border-radius: 50px;
      width: 450px;
      max-width: 90%;

      i {
        padding: 0 10px;
      }
    }
  }

  .btnContinue {
    margin-top: 40px;
    width: 400px;

    @media (max-width: 767px) {
      height: 5%;
      margin-left: 20px;
      width: 50%;
    }
  }
}

@media (min-width: 1024px) {
  .typeSelector {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 44px;

    button {
      width: auto;
    }
  }
}
