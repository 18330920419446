@import "../../../../css/_mixins.scss";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 75vh;
  padding: 16px;
  padding-left: 0;
  text-align: center;
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    overflow-x: hidden;
  }

  .content {
    display: flex;
    align-items: center;
    width: 80%;
    @media (max-width: 767px) {
      flex-direction: column;
      margin-top: 0px;
      width: 100%;
    }

    .section {
      width: 50%;
      @media (max-width: 767px) {
        display: grid;
        place-items: center;
        width: 100%;
        margin-left: 20px;
      }
      &:not(:first-child) {
        margin-left: 80px;
      }
      .colorPickerButtons {
        @media (max-width: 767px) {
          width: 60%;
          justify-content: center;
          margin: 0px;
        }
      }
      .title {
        margin: 60px 0 30px;
        text-align: center;
        color: var(--red);
        font-size: 32px;
        font-style: normal;
        font-weight: 700;

        @include tablet {
          font-size: 36px;
          line-height: 48px;
        }
        @media (max-width: 767px) {
          padding: 0;
          flex-direction: row;
          font-size: 18px;
          white-space: nowrap;
        }
      }

      .inputCaption {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        height: 60px;
        font-size: 22px;
        color: var(--gray);
        border: 1px solid var(--gray);
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #707070;
        border-radius: 25px;
        text-align: center;
        @media (max-width: 767px) {
          width: 70%;
          font-size: 17px;
          overflow: visible;
          white-space: initial;
          font-weight: bold;
          margin-bottom: 30px;
        }
      }

      .subTitle {
        font-size: 18px;
        width: 400px;
        font-size: 22px;
        font-weight: bold;
        color: var(--gray);
        @media (max-width: 767px) {
          margin-bottom: 20px;
        }
      }

      .row {
        display: flex;
        margin-top: 16px;

        .colorCode {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50%;
          height: 60px;
          margin-left: 16px;
          font-size: 24px;
          color: var(--gray);
          border: 1px solid var(--gray);
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #707070;
          border-radius: 25px;
          @media (max-width: 767px) {
            font-size: 16px;
            white-space: pre-line;
            flex-direction: column;
            flex-wrap: wrap;
          }
        }

        .customBackground {
          width: 180px;
          height: 100px;
          border: 1px solid var(--gray);

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .imageUploader {
          margin-left: 32px;

          .btnUpload {
            padding: 0 30px;
            font-size: 24px;

            i {
              margin-left: 8px;
            }
          }

          .tip {
            margin-top: 8px;
            font-size: 14px;
            color: var(--gray);
          }
        }
        .overlayText {
          position: absolute;
        }
      }

      .previewWrapper {
        width: 420px;
        margin-top: 8px;
        margin-right: 0px;
        box-shadow: 3px 3px 40px #00000029;
        @media (max-width: 767px) {
          display: flex;
          width: 400px;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          justify-self: center;
          justify-items: center;
          margin-top: 0px;
        }

        .content {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: calc(100% - 100px);
          padding: 40px;

          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: white;
            opacity: 0.8;
          }

          img {
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .redeemForm {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            padding: 32px 40px;
            background-color: white;
            box-shadow: 3px 3px 40px #00000029;
            border-radius: 5px;

            .code {
              width: 100%;
              height: 35px;
              line-height: 35px;
              border-radius: 15px;
              border: 1px solid #006784;
              color: #006784;
              font-size: 18px;
              font-weight: bold;
              text-transform: uppercase;
            }

            .lblCode {
              margin-top: 4px;
              font-size: 10px;
              font-weight: bold;
              color: var(--gray);
            }

            .email {
              width: 100%;
              height: 35px;
              margin-top: 16px;
              line-height: 35px;
              border-radius: 15px;
              border: 1px solid #006784;
              color: #006784;
              font-size: 14px;
            }

            .tip {
              margin-top: 4px;
              font-size: 8px;
              font-weight: bold;
              color: var(--gray);
            }

            .terms {
              display: flex;
              align-items: flex-start;
              margin-top: 24px;

              .checkbox {
                position: relative;
                flex-shrink: 0;
                width: 12px;
                height: 12px;
                border: 1px solid #006784;
                color: #006784;
                border-radius: 6px;

                &::before {
                  content: "";
                  position: absolute;
                  inset: 2px;
                  background-color: currentColor;
                  border-radius: 4px;
                }
              }

              .lblTerms {
                margin-left: 4px;
                font-size: 9px;
                text-align: left;

                span {
                  text-decoration: underline;
                  color: var(--red);
                }
              }
            }

            .button {
              width: 100%;
              height: 35px;
              margin-top: 24px;
              line-height: 35px;
              border-radius: 35px;
              background-color: #006784;
              color: white;
              font-size: 14px;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
  .preview {
    display: grid;
    place-items: center;
    width: 80%;
    @media (max-width: 767px) {
      width: fit-content;
      justify-self: center;
      justify-items: center;
      align-items: center;
      justify-content: center;
      align-content: center;
    }
  }
  .input {
    width: 400px;
    height: 60px;
    margin-top: 20px;
    text-align: center;
    border: 2px solid var(--gray);
    border-radius: 30px;
    color: var(--gray);
    font-size: 26px;
    letter-spacing: 0px;
  }

  .lblInvalid {
    font-size: 14px;
    color: var(--red);
  }

  .lblAmount {
    margin-top: 20px;
    font-size: 50px;
    font-weight: bold;
    color: var(--red);
  }

  .lblInfo {
    font-size: 18px;
    color: var(--red);
    font-weight: bold;
  }

  .btnContinue {
    margin-top: 40px;
    width: 400px;

    @media (max-width: 767px) {
      height: 5%;
      margin-left: 20px;
      width: 50%;
    }
  }
}
