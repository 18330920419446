@import "../../../../css/_mixins.scss";

.container {
  display: flex;
  width: 100%;
  min-height: 80vh;
  text-align: center;
  @media (max-width: 767px) {
    flex-direction: column;
    place-items: center;
  }

  @include small-desktop {
    display: block;
  }

  .charityContent {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 500px;
    padding: 64px 32px;
    flex-shrink: 0;
    text-align: left;
    background-color: var(--concrete);

    @include small-desktop {
      width: 100%;
      align-items: center;
    }

    @media (max-width: 767px) {
      padding: auto;
      margin-top: 10px;
      padding-top: 20px;
    }

    .lblCharity {
      font-size: 20px;
      line-height: 28px;
      font-weight: bold;
      letter-spacing: -0.46px;
      color: #707070;
      @media (max-width: 767px) {
        font-size: 20px;
        line-height: normal;
      }
    }

    .preselectedCharity {
      display: flex;
      align-items: center;
      margin-top: 40px;
      @media (max-width: 767px) {
        margin-top: 20px;
        display: flex;
        align-items: left;
        justify-content: left;
      }

      .itemImage {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 115px;
        height: 71px;
        flex-shrink: 0;
        background: var(--white);
        border-radius: 6px;

        img {
          max-width: 100%;
        }

        .btnRemove {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: -15px;
          left: -15px;
          width: 30px;
          height: 30px;
          background-color: white;
          color: var(--red);
          font-size: 20px;
          border: 1px solid var(--gray);
          border-radius: 30px;
          cursor: pointer;
        }
      }

      .itemDetail {
        flex: 1;
        margin-left: 20px;

        @include small-desktop {
          margin-left: 8px;
        }

        .itemName {
          color: var(--red);
          font-size: 19px;
          line-height: 23px;
          font-weight: 700;
          letter-spacing: -0.38px;

          @include mobile {
            font-size: 18px;
          }
        }

        .itemDesc {
          margin-top: 5px;
          color: var(--gray);
          font-size: 16px;
          font-style: italic;
          line-height: 19px;
          letter-spacing: -0.32px;

          @include mobile {
            font-size: 14px;
          }
        }
      }

      .itemPrice {
        color: var(--gray);
        font-size: 25px;
        line-height: 30px;
        text-align: right;
        letter-spacing: -0.5px;

        @include mobile {
          font-size: 22px;
        }
      }
    }

    .actions {      
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 40px auto 0 auto;

      .btnContinue {
        width: 324px;
        height: 48px;
        font-size: 18px;
        
        @include mobile {
          width: 100%;
        }
      }

      .btnStepBack {
        position: relative;
        width: 205px;
        height: 41px;
        margin-top: 24px;
        color: var(--text-gray);
        font-size: 17px;
        font-weight: bold;
        border: 3px solid var(--text-gray);
        background-color: var(--white);
        border-radius: 35px;

        i {
          position: absolute;
          top: 50%;
          right: 16px;
          transform: translateY(-50%);
          font-size: 20px;
        }
      }
    }
  }

  .content {
    flex: 1;
    max-width: calc(100% - 500px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 40px;
    margin-top: 40px;

    @include mobile {
      max-width: max-content;
      display: block;
      padding: 0 0px;
    }
  }
}
