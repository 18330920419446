@import "../../../../css/_mixins.scss";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 75vh;
  padding: 16px;
  text-align: center;

  .title {
    text-align: center;
    color: var(--red);
    font-size: 45px;
    font-style: normal;
    font-weight: 700;

    @include tablet {
      font-size: 36px;
      line-height: 48px;
    }
  }

  .sendDate {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 44px;
    gap: 10px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      button {
        width: 100%;
      }
    }
  }

  .strDate {
    margin-top: 32px;
    font-size: 24px;
    font-weight: 700;
    color: var(--gray);

    span {
      color: var(--red);
    }

    @include tablet {
      margin: 16px 0 0 0;
    }
  }

  .btnContinue {
    margin-top: 100px;
    width: 462px;

    @include mobile {
      width: 100%;
    }
  }
}

.btnSendLater {
  position: relative;
  min-width: 300px;
  height: 60px;
  padding: 0 50px;
  margin-left: 24px;
  border-radius: 30px;
  letter-spacing: 0;
  white-space: nowrap;
  font-family: var(--font-family-montserrat);
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  background-color: var(--white);
  border: 3px solid var(--yellow);
  color: var(--white);

 @media screen and (max-width:768px) {
  font-size: 28px;
  width:100%;
  margin: 0;
 } 
  &.red {
    border: 3px solid var(--red);
    color: var(--red);
  }

  &.yellow {
    border: 3px solid var(--yellow);
    color: var(--yellow);
  }

  i {
    margin-left: 16px;
  }
}
