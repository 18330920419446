@import "../../../../css/_mixins.scss";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 75vh;
  padding: 16px;
  text-align: center;

  .title {
    text-align: center;
    color: var(--red);
    font-size: 40px;
    font-style: normal;
    font-weight: 700;

    @include small-desktop {
      font-size: 36px;
      line-height: 48px;
    }
  }

  .content {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    max-width: 100%;
    margin-top: 30px;

    @include small-desktop {
      display: block;
      margin-top: 32px;
    }

    .detailContent {
      padding-right: 80px;
      padding-left: 80px;
      width: 1000px;
      max-width: 100%;

      @include small-desktop {
        padding-right: 0;
        padding-left: 0;
        width: 100%;
      }

      .detailItem {
        display: flex;
        position: relative;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        max-width: 100%;
        margin-top: 24px;
        font-size: 22px;
        color: var(--red);
        text-align: center;
        color: #c44042;
        font-weight: 700;

        @media screen and (max-width: 600px) {
          flex-direction: column;
          text-align: left;
          justify-content: flex-start;
          align-items: flex-start;
        }

        .btnClear {
          position: absolute;
          top: -24px;
          right: 0;
          font-size: 14px;
          text-decoration: underline;
          cursor: pointer;
        }

        .recipients {
          @media screen and (max-width: 1000px) {
            width: 100%;
          }

          .recipient {
            position: relative;
            display: flex;
            gap: 20px;
            justify-content: space-between;
            align-items: center;
            padding-right: 50px;
            @media screen and (max-width: 1000px) {
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
              gap: initial;
              padding-right: 35px;
            }

            input {
              @media screen and (max-width: 800px) {
                font-size: 4vw;
                padding: 10px 7px;
                width: 100%;
              }
            }

            &:not(:first-child) {
              margin-top: 16px;
            }

            i {
              position: absolute;
              top: 50%;
              right: 0;
              transform: translateY(-50%);
              font-size: 30px;
              cursor: pointer;
            }
          }
          .addRecipientButton {
            width: fit-content;
            text-align: center;
            margin-top: 16px;
            margin-left: auto;
            margin-right: 0;
            padding: 3px 10px;
            border: 1px solid var(--yellow);
            border-radius: 50px;
            background-color: var(--white);
            color: var(--yellow);
            font-size: 13px;
            font-weight: 700;
            text-decoration: none;
            cursor: pointer;
            @media screen and (max-width: 850px) {
              margin: 16px auto 0 auto;
            }
            i {
              margin: 12px;
            }
          }
        }

        input,
        textarea {
          flex: 1;
          max-width: 80%;
          margin-left: 16px;
          padding: 10px 24px;
          border: 1px solid var(--gray);
          border-radius: 35px;
          font-size: 22px;
          color: var(--gray);

          @media screen and (max-width: 600px) {
            width: 100%;
            max-width: 100% !important;
            margin: 16px 0 0 0;
          }
        }
      }

      .info {
        margin-top: 8px;
        font-size: 20px;
        font-weight: bold;
        color: var(--red);
        font-style: italic;
        text-align: right;
      }
    }

    .printSizeWarning {
      color: var(--yellow);
      text-align: center;
      font-size: 15pt;
      margin: 20px;
    }

    .uploadContent {
      position: relative;
      padding-right: 50px;
      font-size: 22px;
      color: var(--gray);
      text-align: center;
      font-weight: 700;
      max-width: 40%;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @include small-desktop {
        padding: 0;
        max-width: 100%;
        margin-top: 55px;
      }

      .btnDownloadRecipient {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 16px;
        width: 314px;
        height: 50px;
        border: 3px solid var(--red);
        border-radius: 35px;
        background-color: var(--white);
        color: var(--red);
        font-size: 20px;
        font-weight: 700;
        text-decoration: none;
        cursor: pointer;

        i {
          margin-left: 8px;
        }
      }

      .btnInfo {
        position: absolute;
        top: 58px;
        right: 0;
        width: 24px;
        height: 24px;
        font-size: 16px;
        border: 1px solid var(--red);
        border-radius: 30px;
        color: var(--red);
        cursor: pointer;

        @include small-desktop {
          right: calc(50% - 185px);
        }
      }

      .uploadIssues {
        text-align: left;
        margin-top: 40px;
        font-size: 0.8em;
        font-weight: normal;
        h3 {
          color: #f00;
        }
        ul > li {
          text-align: left;
          em {
            font-style: italic;
            font-weight: 700;
            color: #b88;
            padding-left: 15px;
          }
        }
      }
    }
  }

  .btnContinue {
    margin-top: 80px;
    width: 462px;

    @include mobile {
      width: 100%;
    }
  }
}
