@import "../../css/_mixins.scss";

.container {

  .redBarWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .stepLabel {
      font-size: 35px;
      line-height: 32px;
      color: var(--white);
      letter-spacing: -0.78px;
      white-space: nowrap;
      margin: 0 auto;
      display: flex;

      .checkoutLabel {
        margin-inline: 10px;
        font-size: 16px;
        align-self: flex-end;
        position: relative;
        margin-bottom: -5px;
        letter-spacing: 0.5px;

        @media (max-width: 430px) {
          display: none;
        }
      }

      @media (max-width: 600px) {
        margin-left: 0;
      }

      @include tablet {
        text-align: center;
      }
    }
    .cartDetail {
      color: white;
      font-size: x-large;
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }

  // .stepLabel {
  //   display        : flex;
  //   justify-content: center;
  //   align-items    : center;
  //   font-size      : 35px;
  //   line-height    : 32px;
  //   color          : var(--white);
  //   letter-spacing : -0.78px;

  //   @include tablet {
  //     display   : block;
  //     text-align: center;
  //   }

  //   span {
  //     margin        : 8px 0 0 8px;
  //     font-size     : 26px;
  //     line-height   : 32px;
  //     letter-spacing: -0.52px;

  //     @include mobile {
  //       display: block;
  //     }
  //   }

  //   .cartDetail {
  //     display       : flex;
  //     align-items   : center;
  //     position      : absolute;
  //     right         : 80px;
  //     font-size     : 39px;
  //     letter-spacing: -0.78px;

  //     @include tablet {
  //       position       : relative;
  //       justify-content: center;
  //       right          : 0;
  //       margin-top     : 16px;
  //     }

  //     i {
  //       margin-right: 10px;
  //       font-size   : 50px;
  //     }
  //   }
  // }

  .content {
    position      : relative;

    .btnStepBack {
      position        : absolute;
      bottom          : 40px;
      right           : 60px;
      width           : 205px;
      height          : 41px;
      color           : var(--text-gray);
      font-size       : 17px;
      font-weight     : bold;
      border          : 3px solid var(--text-gray);
      background-color: var(--white);
      border-radius   : 35px;

      i {
        position : absolute;
        top      : 50%;
        right    : 16px;
        transform: translateY(-50%);
        font-size: 20px;
      }
    }
  }
}