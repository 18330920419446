.wrapper {
    .filterTrigger {
      font-size: 22px;
      font-weight: bold;
      text-align: right;
      color: var(--yellow);
    }

    .filterList {
      width: 100%;
      max-height: 400px;
      overflow: auto;
      @media (max-width: 767px) {
        max-height: 160px;
        overflow-y: auto;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 1;

      }

      .filterItem {
        padding: 4px 16px;
        font-size: 16px;
        color: var(--gray);
        cursor: pointer;

        &.clear {
          color: var(--red);
        }
      }
    }

    @media (max-width: 767px) {
      position: relative;
    }
  }