@import "../../../../css/_mixins.scss";

.container {
  display        : flex;
  justify-content: center;
  align-items    : center;
  flex-direction : column;
  width          : 100%;
  min-height     : 75vh;
  padding        : 16px;
  text-align     : center;

  .title {
    text-align : center;
    color      : var(--red);
    font-size  : 45px;
    font-style : normal;
    font-weight: 700;

    @include tablet {
      font-size  : 36px;
      line-height: 48px;
    }
  }

  .content {
    display        : flex;
    justify-content: center;
    align-items    : center;
    margin-top     : 40px;

    @include tablet {
      display: block;
    }

    .detailContent {
      text-align: center;

      .greeting {
        font-size     : 26px;
        font-weight   : 700;
        line-height   : 32px;
        color         : var(--red);
        letter-spacing: 0px;
      }

      .detailLabel {
        margin-top    : 18px;
        font-size     : 18px;
        color         : var(--gray);
        letter-spacing: 0px;

        span {
          color: var(--red);
        }
      }

      .corpLogo {
        display: block;
        margin: 16px auto 0;
        max-width: 100px;
      }
    }

    .cardWrapper {
      width        : 336px;
      margin-left  : 66px;
      background   : #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow   : 3px 3px 40px #00000029;
      border-radius: 14px 14px 10px 10px;
      overflow     : hidden;

      @include tablet {
        margin: 32px 0 0 0;
      }

      .imgWrapper {
        position: relative;

        img {
          width: 100%;
        }

        .cutomSection {
          position: absolute;
          top: 62%;
          left: 5%;
          right: 5%;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;

          p {
            margin: 0;
          }
        }
      }


      .cardContent {
        padding       : 18px 24px;
        font-size     : 16px;
        font-weight: bold;
        line-height   : 27px;
        letter-spacing: var(--unnamed-character-spacing-0);
        color         : var(--gray);
        text-align    : left;
        letter-spacing: 0px;
      }

      .cardFooter {
        display        : flex;
        align-items    : center;
        justify-content: space-between;
        padding        : 18px 24px;
        color          : var(--red);
        border-top     : 1px solid #CECECE;
        font-weight    : 700;

        .donationAmount {
          font-size     : 35px;
          letter-spacing: -0.7px;
        }

        .donationLabel {
          font-size     : 17px;
          letter-spacing: -0.34px;
        }
      }
    }
  }

  .typeMethod {
    display        : flex;
    justify-content: center;
    margin-top     : 66px;
    max-width      : 100%;

    @include tablet {
      display: block;
    }

    button {

      @include mobile {
        width  : 100%;
        padding: 0;
      }

      &:not(:first-child) {
        margin-left: 32px;

        @include mobile {
          margin: 16px 0 0 0;
        }
      }
    }
  }
}