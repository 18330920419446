$large-desktop: 1920px;
$medium-desktop: 1650px;
$small-desktop: 1080px;
$tablet: 768px;
$mobile: 428px;

@mixin large-desktop {
  @media (max-width: $large-desktop) {
    @content;
  }
}

@mixin medium-desktop {
  @media (max-width: $medium-desktop) {
    @content;
  }
}

@mixin small-desktop {
  @media (max-width: $small-desktop) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: $tablet) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: $mobile) {
    @content;
  }
}
